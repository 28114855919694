import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import "./SolutionPage.css";
import ListItem from "./HomeComponents/ListItem";
// import RangeSlider from "./common/RangeSlider";
import VerticalRangeSlider from "./common/VerticalRangeSlider";

const SolutionsPage = () => {
  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }

  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    //
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const InnovativeSolutionsArr = [
    {
      title: "Code Editor",
      desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit nulla elementum sit amet metus",
      img: "/images/code-editor.svg",
    },
    {
      title: "Media Storage",
      desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit nulla elementum sit amet metus",
      img: "/images/media-storage.svg",
    },
    {
      title: "SSL Certificate",
      desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit nulla elementum sit amet metus",
      img: "/images/ssl-certificate.svg",
    },
    {
      title: "Personal Domain",
      desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit nulla elementum sit amet metus",
      img: "/images/domain.svg",
    },
    {
      title: "Domain Transfer",
      desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit nulla elementum sit amet metus",
      img: "/images/domain-transfer.svg",
    },
    {
      title: "Developer Friendly",
      desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit nulla elementum sit amet metus",
      img: "/images/developer.svg",
    },
  ];

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const featureListStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    padding: "20px",
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "65rem",
        position: "relative",
        backgroundImage: isMobile ? `url(./main-bg.jpg)` : `url(./main-bg.jpg)`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundColor: "#141414",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      {isMobile ? (
        <div className="features-page-solution">
          <div className="heading-dotted">
            Solutions <span></span>
          </div>
          <div className="features-section-solution">
            <Row>
              {/* <div className="col-lg-2 col-md-1"></div> */}
              <div className="col-lg-8 col-md-12">
                <div className="solution-posts-inner">
                  {InnovativeSolutionsArr.map((item, index) => (
                    <div
                      className="solution-post"
                      key={index}
                      style={{
                        backgroundImage: "linear-gradient(#FFFFFF, #EFEFEF)",

                        height: "14rem",
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        className="solution-card-solution"
                        style={{
                          padding: "20px",
                          marginBottom: "20px",
                          borderRadius: "10px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div
                          className="in-border"
                          style={{
                            alignContent: "center",
                            height: "90px",
                            width: "90px",
                            borderColor: "yellow",
                            border: "2px solid #E97730",
                            borderRadius: "50%",
                            margin: "auto",
                            backgroundColor: "transparent",
                            marginTop: "-32%",
                            padding: "0",
                            position: "relative",
                            top: "0.5rem",
                          }}
                        >
                          <div
                            className="in-border"
                            style={{
                              height: "80px",
                              width: "80px",
                              padding: "10px",
                              borderColor: "yellow",
                              border: "2px solid #E97730",
                              borderRadius: "50%",
                              margin: "auto",
                              backgroundColor: "#E97730",
                            }}
                          >
                            <figure>
                              <img src={item.img} alt={item.img} />
                            </figure>
                          </div>
                        </div>
                        <div className="content-solution">
                          <h3
                            style={{
                              fontSize: "20px",
                              fontWeight: "500",
                              color: "#2D394B",
                              textAlign: "center",
                              margin: "20px 0",
                            }}
                          >
                            {item.title}
                          </h3>
                          <p
                            className="description-solution"
                            style={{ textAlign: "center" }}
                          >
                            {item.desc}
                          </p>
                        </div>
                        <div className="log-in" style={{ marginLeft: "3rem" }}>
                          <a href="/blogmore" className="media-link">
                            <div
                              className="media-banner"
                              style={{ width: "auto" }}
                            >
                              <img
                                className="normal-banner"
                                src="/images/read-more-btn.png"
                                alt=""
                              />
                              <img
                                className="hover-img-banner"
                                src={null}
                                alt=""
                              />
                              <span
                                className="login-text"
                                style={{ fontSize: "20px", color: "#07528B" }}
                              >
                                Read More
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-1"></div> */}
            </Row>
          </div>
        </div>
      ) : (
        <div
          className="features-page-solution"
          style={{ height: "50rem", padding: "10rem" }}
        >
          <div className="heading-dotted">
            Solutions <span></span>
          </div>
          <div className="features-section-solution">
            <Row>
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <div className="solution-posts-inner">
                  {InnovativeSolutionsArr.map((item, index) => (
                    <div className="solution-post">
                      <div
                        style={{
                          backgroundImage: "linear-gradient(#FFFFFF, #EFEFEF)",
                          borderRadius: "15px",
                        }}
                      >
                        <div className="solution-card-solution" key={index}>
                          <div
                            className="in-border"
                            style={{
                              alignContent: "center",
                              height: "90px",
                              width: "90px",
                              // padding: "5px",
                              borderColor: "yellow",
                              border: "2px solid #E97730",
                              borderRadius: "50%",
                              // display: "table",
                              margin: "auto",
                              backgroundColor: "transparent",
                              marginTop: "-25%",
                              padding: "0",
                              position: "relative",
                              // top: "1rem",
                            }}
                          >
                            <div
                              className="in-border"
                              style={{
                                height: "80px",
                                width: "80px",
                                padding: "10px",
                                borderColor: "yellow",
                                border: "2px solid #E97730",
                                borderRadius: "50%",
                                // display: "table",
                                margin: "auto",
                                backgroundColor: "#E97730",
                              }}
                            >
                              <figure>
                                <img src={item.img} alt={item.img} />
                              </figure>
                            </div>
                          </div>
                          <div className="content-solution">
                            <h3
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                color: "#2D394B",
                                textAlign: "center",
                                margin: "35px 0 10px",
                              }}
                            >
                              {item.title}
                            </h3>
                            <p
                              className="description-solution"
                              style={{ fontSize: "16px", fontWeight: "500" }}
                            >
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit nulla elementum sit amet metus
                            </p>
                          </div>
                          <div
                            className="log-in"
                            style={{
                              display: "grid",
                              position: "relative",

                              justifyContent: "center",
                              marginTop: "10%",
                            }}
                          >
                            <a href="/blogmore" className="media-link">
                              <div
                                className="media-banner"
                                style={{ width: "auto" }}
                              >
                                <img
                                  className="normal-banner"
                                  src="/images/read-more-btn.png"
                                  alt=""
                                />
                                <img
                                  className="hover-img-banner"
                                  src="/images/read-more-btn.png"
                                  alt=""
                                />
                                <span
                                  className="login-text"
                                  style={{
                                    fontSize: "20px",
                                    color: "white", //"#07528B"
                                  }}
                                  onMouseOver={(e) =>
                                    (e.target.style.color = "#07528B")
                                  } // Change color on hover
                                  onMouseOut={(e) =>
                                    (e.target.style.color = "white")
                                  }
                                >
                                  Read More
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default SolutionsPage;
