import React, { useEffect, useState } from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import "./SupportPage.css";
import "./MachineStatus.css";
import instance, {
  apiDecrypteRequest,
  apiEncryptRequest,
  decryptData,
} from "../../Api";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../AuthContext";
import Loader from "../common/Loader";
import AppToast from "../../AppToast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
// import RangeSlider from "./common/RangeSlider";

const MachineStatus = () => {
  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }
  const location = useLocation();
  const { smuser } = useAuth();
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [activeButton, setActiveButton] = useState("");
  const [loading, setLoading] = useState(true);
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");

  const [machineData, setMachineData] = useState([]);

  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);
  // const [showNewConPass, setShowNewConPass] = useState(false);

  const [changePass, setChangePass] = useState(false);
  const [vmRes, setVmRes] = useState(null);
  const [monitorData, setMonitorData] = useState([]);
  const vmDetails = location.state ? location.state.vmDetails : null;
  //console.log(vmDetails);

  const [isShowBackupPopup, setShowBackupPopup] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copied3, setCopied3] = useState(false);
  const [copied4, setCopied4] = useState(false);
  const [copied5, setCopied5] = useState(false);
  const [copied6, setCopied6] = useState(false);
  const [copied7, setCopied7] = useState(false);

  const [showVNCPass, setShowVNCPass] = useState(false);
  const [changeVNCPass, setChangeVNCPass] = useState(false);
  const [privateKey, setPrivateKey] = useState("");

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isFlipped, setIsFlipped] = useState(false);
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  const isValidPassword = (password) => {
    // Regular expression to match only the allowed characters
    const regex = /^[a-zA-Z0-9]+$/;

    // Check if the password matches the regex and has a minimum length of 8 characters
    return regex.test(password) && password.length >= 8;
  };

  const VNC = async (machineId) => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: machineId,
    };
    // console.log(payload, "------- VMC");

    // const res = {
    //   status: true,
    //   info: {
    //     port: "5909",
    //     ip: "175.111.97.101",
    //     password: "test123",
    //   },
    //   code: "200",
    // };
    try {
      const vncResponse = await instance.post("vm/vnc", payload);
      //console.log(vncResponse.data, "====VMC");
      if (vncResponse.data.status) {
        setLoading(false);
        //console.log(vncResponse.data.url);
        const pushUrl = `${vncResponse.data.url}`;
        window.location.href = pushUrl;
        // SetVncPopup(true);
        // const info = vncResponse.data.info;
        // //console.log(info, "INFOOOO");
        // setVNCinfo(info);
      }
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const regenerateSSH = async (machineId) => {
    setLoading(true);

    const payload = {
      user_id: smuser.id,
      vm_id: machineId,
    };
    // console.log(payload, "------- VMC");
    try {
      const encryptedResponse = await apiEncryptRequest(payload);
      const loginUserResponse = await instance.post(
        "/regenerate",
        encryptedResponse
      );
      const loginResponse = await decryptData(loginUserResponse.data);

      // setPrivateKey(loginResponse.private_key);
      if (loginResponse.status) {
        handleDownload(loginResponse.private_key);
      }

      if (loginResponse.message) {
        toast((t) => (
          <AppToast
            id={t.id}
            message={loginResponse.message}
            isMobile={isMobile}
          />
        ));
      }
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const handleDownload = (privateKey) => {
    // e.preventDefault();

    const pemFile = "ssh_key.pem";
    const pemFileContent = privateKey;

    const blob = new Blob([pemFileContent], {
      type: "application/x-pem-file",
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = pemFile;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const UpdateMachinePass = async () => {
    setLoading(true);
    if (newPass !== "" && confPass !== "" && newPass === confPass) {
      if (isValidPassword(newPass)) {
        const payload = {
          user_id: smuser.id,
          vm_id: vmDetails,
          password: newPass,
          confirm_password: confPass,
        };
        try {
          // First API call to encrypt the request
          const encryptedResponse = await apiEncryptRequest(payload);
          //console.log(encryptedResponse, "=encryptedResponse");

          // Second API call to with encrypted response
          const loginUserResponse = await instance.post(
            "/vm/change-server-details",
            encryptedResponse
          );
          //console.log(loginUserResponse.data, "====change-server-details");

          // Third API call to decrypt the response
          const loginResponse = await decryptData(loginUserResponse.data);
          setNewPass(null);
          setConfPass(null);
          GetMyMachines();

          //console.log(loginResponse, "==!==!==change-server-details");
        } catch (error) {
          console.error(
            "Error during the change-server-details process:",
            error
          );
        }
      } else {
        toast((t) => (
          <AppToast
            id={t.id}
            message={"Password Must Contain Alphabets and Numerics"}
            isMobile={isMobile}
          />
        ));
      }
    } else {
      if (newPass == "" || confPass == "") {
        toast((t) => (
          <AppToast
            id={t.id}
            message={"Password Required"}
            isMobile={isMobile}
          />
        ));
      } else if (newPass !== confPass) {
        toast((t) => (
          <AppToast
            id={t.id}
            message={"New Password and Confirm Password not matching"}
            isMobile={isMobile}
          />
        ));
      }
    }
    setLoading(false);
  };

  const GetMyMachines = async () => {
    const payload = {
      user_id: smuser.id,
      vm_id: vmDetails,
    };
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const loginUserResponse = await instance.post(
        "/vm/stats",
        encryptedResponse
      );
      //console.log(loginUserResponse.data, "====/vm/stats");

      // Third API call to decrypt the login response
      const Response = await decryptData(loginUserResponse.data);

      //console.log(Response, "==!==!==stats");
      setVmRes(Response);
      const vm = Response.vm;

      setMonitorData(vm);
      console.log(vm, "==!==!==stats");
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetMyMachines();
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const [index, setIndex] = useState(0);

  const featureListStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    padding: "20px",
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "65rem",
        position: "relative",
        backgroundImage: isMobile ? `url(./main-bg.jpg)` : `url(./main-bg.jpg)`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundColor: "#141414",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
        // backgroundColor: "white",
      }}
    >
      {isMobile ? (
        <div className="" style={{ height: "50rem" }}>
          <div className="heading-dotted-support">
            Server Stats <span></span>
          </div>
          <div
            style={{
              marginLeft: "1rem",
              marginBottom: "-20rem",
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
              justifyItems: "center",
              gridRowGap: "50px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                padding: "20px 0px",
                flexWrap: "nowrap",
              }}
            >
              <img
                src="/images/admin/06-View-Stats/server.png"
                style={{
                  width: "80%",
                  height: "500px",
                  marginTop: "-30px",
                  marginBottom: "-15rem",
                }}
                className="bg-image"
              />
              <div
                className="stat"
                style={{
                  position: "absolute",
                  maxWidth: "15rem",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                <div className="machine-icon-edit-profile">
                  <img src={"/images/admin/06-View-Stats/switch.svg"} />
                </div>
                <div
                  className="machine-title"
                  style={{
                    backgroundColor: monitorData.status == 1 ? "green" : "red",
                  }}
                >
                  {monitorData && monitorData.status == 1 ? "ON" : "OFF"}
                </div>
                <div className="mid-portion" />
                <div className="machine-subtitle theme-bg-blue">
                  {monitorData && monitorData.vm_name}
                </div>
              </div>

              <img
                src="/images/admin/06-View-Stats/server.png"
                style={{
                  width: "80%",
                  height: "500px",
                  marginTop: "-30px",
                  marginBottom: "-15rem",
                }}
                className="bg-image"
              />
              <div
                className="stat"
                style={{
                  position: "absolute",
                  maxWidth: "30rem",
                  marginTop: "15rem",
                  marginLeft: "1rem",
                }}
              >
                <div className="machine-icon-edit-profile">
                  <img src={"/admin/images/admin/13-Profile/server_icon.png"} />
                </div>
                <div className="machine-title theme-bg-orange">CPU</div>
                <div className="mid-portion" />
                <div className="machine-subtitle theme-bg-blue">
                  {monitorData && monitorData.cpu}
                </div>
              </div>

              <img
                src="/images/admin/06-View-Stats/server.png"
                style={{
                  width: "80%",
                  height: "500px",
                  marginTop: "-30px",
                  marginBottom: "-15rem",
                }}
                className="bg-image"
              />
              <div
                className="stat"
                style={{
                  position: "absolute",
                  maxWidth: "15rem",
                  marginTop: "29rem",
                  marginLeft: "2rem",
                }}
              >
                <div className="machine-icon-edit-profile">
                  <img src={"/images/admin/06-View-Stats/ram-icon.svg"} />
                </div>
                <div className="machine-title theme-bg-orange">RAM</div>
                <div className="mid-portion" />
                <div className="machine-subtitle theme-bg-blue">
                  {monitorData && monitorData.ram / 1024} GB
                </div>
              </div>

              <img
                src="/images/admin/06-View-Stats/server.png"
                style={{ width: "275px", height: "500px", marginTop: "-30px" }}
                className="bg-image"
              />
              <div
                className="stat"
                style={{
                  width: "80%",
                  position: "absolute",
                  maxWidth: "15rem",
                  marginTop: "44rem",
                  marginLeft: "2rem",
                }}
              >
                <div className="machine-icon-edit-profile">
                  <img
                    src={"/images/admin/06-View-Stats/disk-space-icon.svg"}
                  />
                </div>
                <div className="machine-title theme-bg-orange">Storage</div>
                <div className="mid-portion" />
                <div className="machine-subtitle theme-bg-blue">
                  {monitorData && monitorData.disk_type == "hdd"
                    ? monitorData.hard_disk
                    : monitorData.disk_type == "nvme"
                    ? monitorData.nvme
                    : monitorData.ssd}{" "}
                  GB
                </div>
              </div>

              <img
                src="/images/admin/06-View-Stats/server.png"
                style={{ width: "275px", height: "500px", marginTop: "-17rem" }}
                className="bg-image"
              />
              <div
                className="stat"
                style={{
                  width: "80%",
                  position: "absolute",
                  maxWidth: "15rem",
                  marginTop: "58rem",
                  marginLeft: "2rem",
                }}
              >
                <div className="machine-icon-edit-profile">
                  <img
                    src={"/images/admin/06-View-Stats/disk-space-icon.svg"}
                  />
                </div>
                <div className="machine-title theme-bg-orange">Server Type</div>
                <div className="mid-portion" />
                <div className="machine-subtitle theme-bg-blue">
                  {monitorData && monitorData.support_type}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              minHeight: "34rem",
              backgroundImage: `url("/images/blue-box-bg.svg")`,
              backgroundSize: "cover",
              top: "5rem",
              // height: "40vh",
              width: "100%",
              // marginLeft: "25px",
              // display: "flex",

              padding: "1px 25px",
              position: "relative",
              backgroundColor: "#07528b", // Use backgroundColor instead of background
              borderRadius: "12px",
              // flexWrap: "wrap",
            }}
          >
            <div>
              <div
                style={{
                  position: "relative",
                  // display: "flex",
                  flexWrap: "wrap",
                  zIndex: "1",
                }}
              >
                <div
                  style={{
                    // width: "50%",
                    padding: "0px 10px",
                  }}
                >
                  {/* GST Number */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid white",
                      borderRadius: "25px",
                      padding: "5px",
                      height: "50px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        paddingTop: "15px",
                        marginLeft: "20px",
                      }}
                    >
                      {" "}
                      IP : {vmRes && vmRes.ip}
                    </p>
                  </div>
                  {/* PAN Number */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid white",
                      borderRadius: "25px",
                      padding: "5px",
                      height: "50px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        paddingTop: "15px",
                        marginLeft: "20px",
                      }}
                    >
                      {" "}
                      UserName : {vmRes && vmRes.vm_username}
                    </p>
                  </div>
                  {/* Passport Or Driving Lic. No. */}
                </div>
                <div
                  style={{
                    // width: "50%",
                    padding: "0px 10px",
                  }}
                >
                  {/* GST Number */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid white",
                      borderRadius: "25px",
                      padding: "5px",
                      height: "50px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        paddingTop: "15px",
                        marginLeft: "20px",
                      }}
                    >
                      {" "}
                      SSH PORT : 6351
                    </p>
                  </div>
                  {/* PAN Number */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid white",
                      borderRadius: "25px",
                      padding: "5px",
                      height: "50px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        paddingTop: "15px",
                        marginLeft: "20px",
                      }}
                    >
                      {" "}
                      Password :{" "}
                      {vmRes && showPass ? vmRes.vm_pass : "••••••••"}
                    </p>
                    <div style={{ position: "absolute", right: "6%" }}>
                      {showPass ? (
                        <FaEyeSlash
                          onClick={() => setShowPass(false)}
                          style={{ color: "white", width: "20px" }}
                        />
                      ) : (
                        <FaEye
                          onClick={() => setShowPass(true)}
                          style={{ color: "white", width: "20px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div onClick={() => setChangePass(!changePass)}>
                <p
                  style={{
                    zIndex: "2",
                    position: "absolute",
                    marginTop: "20px",
                    color: "white",
                    textAlign: "center",
                    fontSize: "20px",
                    paddingTop: "15px",

                    fontWeight: "600",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  Change Password
                </p>
              </div>

              {vmRes && vmRes.vnc_status && (
                <button
                  style={{
                    zIndex: "2",
                    position: "relative",
                    marginTop: "6%",
                    left: "55%",
                    fontWeight: "700",
                    color: "white",
                    height: "55px",

                    width: "10rem",
                    backgroundColor: "#e97730",
                    outline: "4px solid #e97730",
                    border: "4px solid #ffff",
                    borderColor: "white",
                    borderRadius: "30px",
                  }}
                  onClick={() => VNC(monitorData.vm_id)}
                >
                  {" "}
                  <img
                    src={"/vmc-monitor.png"}
                    alt={"/vmc-monitor.png"}
                    style={{
                      marginRight: "5px",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  Connect VNC
                </button>
              )}

              <div>
                <button
                  style={{
                    zIndex: "2",
                    position: "relative",
                    marginTop: "5%",
                    left: "55%",
                    fontWeight: "700",
                    color: "white",
                    height: "55px",

                    width: "10rem",
                    backgroundColor: "#e97730",
                    outline: "4px solid #e97730",
                    border: "4px solid #ffff",
                    borderColor: "white",
                    borderRadius: "30px",
                  }}
                  onClick={() => regenerateSSH(monitorData.vm_id)}
                >
                  {" "}
                  {/* <img
                  src={"/vmc-monitor.png"}
                  alt={"/vmc-monitor.png"}
                  style={{
                    marginRight: "5px",
                    width: "30px",
                    height: "30px",
                  }}
                /> */}
                  Regenerate
                </button>
              </div>
            </div>
            {changePass && (
              <div
                style={{
                  marginBottom: "5rem",
                  position: "relative",
                  display: "flex",
                  flexWrap: "wrap",
                  zIndex: "1",
                }}
              >
                <div
                  style={{
                    width: "23rem",
                    marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: "25px",
                    padding: "5px",
                    height: "50px",
                  }}
                >
                  <input
                    type={showNewPass ? "text" : "password"}
                    id="Password"
                    className="input-signup"
                    name="Password"
                    placeholder="New Password"
                    value={newPass}
                    style={{
                      color: "white",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      flex: "1",
                      padding: "5px",
                    }}
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                  {showNewPass ? (
                    <FaEyeSlash
                      onClick={() => setShowNewPass(false)}
                      style={{ color: "white", width: "20px" }}
                    />
                  ) : (
                    <FaEye
                      onClick={() => setShowNewPass(true)}
                      style={{ color: "white", width: "20px" }}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: "23rem",
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: "25px",
                    padding: "5px",
                    height: "50px",
                  }}
                >
                  <input
                    type={showConfPass ? "text" : "password"}
                    id="Confirm Password"
                    className="input-signup"
                    name="Confirm Password"
                    placeholder="Confirm Password"
                    value={confPass}
                    style={{
                      color: "white",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      flex: "1",
                      padding: "5px",
                    }}
                    onChange={(e) => setConfPass(e.target.value)}
                  />
                  {showConfPass ? (
                    <FaEyeSlash
                      onClick={() => setShowConfPass(false)}
                      style={{ color: "white", width: "20px" }}
                    />
                  ) : (
                    <FaEye
                      onClick={() => setShowConfPass(true)}
                      style={{ color: "white", width: "20px" }}
                    />
                  )}
                </div>
                <div
                  className="log-in"
                  style={{
                    marginTop: "15px",
                    marginLeft: "-8rem",
                    justifyContent: "center",
                  }}
                  onClick={() => UpdateMachinePass()}
                >
                  <a className="media-link">
                    <div
                      className="media-banner"
                      style={{
                        width: "auto",
                        height: "50px",
                        // marginTop: "10px",
                        marginLeft: "10rem",
                      }}
                    >
                      <img
                        className="normal-banner"
                        src="/images/signup-btn-bg.png"
                        alt=""
                        style={{
                          marginTop: "-6px",
                          width: "8rem",
                          height: "4rem",
                        }}
                      />
                      <img
                        className="hover-img-banner"
                        src="/images/search-btn-hover.png"
                        alt="/images/search-btn-hover.png"
                        style={{
                          marginTop: "-6px",
                          width: "8rem",
                          height: "4rem",
                        }}
                      />
                      <span
                        className="login-text"
                        style={{
                          fontSize: "20px",
                          color: "#07528B",
                          marginTop: "0px",
                        }}
                      >
                        Submit
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            )}

            {vmRes && vmRes.vnc_status && (
              <>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: "25px",
                    padding: "5px",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "14px",
                      paddingTop: "15px",
                      marginLeft: "20px",
                    }}
                  >
                    {" "}
                    VNC IP : {vmRes && vmRes.vnc_ip}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: "25px",
                    padding: "5px",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "14px",
                      paddingTop: "15px",
                      marginLeft: "20px",
                    }}
                  >
                    {" "}
                    VNC PORT : {vmRes && vmRes.vnc_port}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: "25px",
                    padding: "5px",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "14px",
                      paddingTop: "15px",
                      marginLeft: "20px",
                    }}
                  >
                    {" "}
                    VNC Password :{" "}
                    {vmRes && showVNCPass ? vmRes.vnc_pass : "••••••••"}
                  </p>
                  <div style={{ position: "absolute", right: "10%" }}>
                    {showVNCPass ? (
                      <FaEyeSlash
                        onClick={() => setShowVNCPass(false)}
                        style={{ color: "white", width: "20px" }}
                      />
                    ) : (
                      <FaEye
                        onClick={() => setShowVNCPass(true)}
                        style={{ color: "white", width: "20px" }}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className="features-page-solution"
          style={{ height: "100%", padding: "5rem" }}
        >
          <div className="heading-dotted-support">
            Server Stats <span></span>
          </div>
          <div className="features-section-solution">
            <Row>
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <div
                  style={{
                    marginBottom: "-20rem",
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    justifyItems: "center",
                    gridRowGap: "50px",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <img
                      src="/images/admin/06-View-Stats/server.png"
                      style={{
                        width: "275px",
                        height: "500px",
                        marginTop: "-30px",
                      }}
                      className="bg-image"
                    />
                    <div
                      className="stat"
                      style={{
                        position: "absolute",
                        maxWidth: "15rem",
                        marginTop: "-15rem",
                        //marginLeft: "-86rem",
                        marginLeft: "-68rem",
                      }}
                    >
                      <div className="machine-icon-edit-profile">
                        <img src={"/images/admin/06-View-Stats/switch.svg"} />
                      </div>
                      <div
                        className="machine-title"
                        style={{
                          backgroundColor:
                            monitorData.status == 1 ? "green" : "red",
                        }}
                      >
                        {monitorData && monitorData.status == 1 ? "ON" : "OFF"}
                      </div>
                      <div className="mid-portion" />
                      <div className="machine-subtitle theme-bg-blue">
                        {monitorData && monitorData.vm_name}
                      </div>
                    </div>

                    <img
                      src="/images/admin/06-View-Stats/server.png"
                      style={{
                        width: "275px",
                        height: "500px",
                        marginTop: "-30px",
                      }}
                      className="bg-image"
                    />
                    <div
                      className="stat"
                      style={{
                        position: "absolute",
                        maxWidth: "15rem",
                        marginTop: "-15rem",
                        //marginLeft: "-51rem",
                        marginLeft: "-34rem",
                      }}
                    >
                      <div className="machine-icon-edit-profile">
                        <img
                          src={"/admin/images/admin/13-Profile/server_icon.png"}
                        />
                      </div>
                      <div className="machine-title theme-bg-orange">CPU</div>
                      <div className="mid-portion" />
                      <div className="machine-subtitle theme-bg-blue">
                        {monitorData && monitorData.cpu}
                      </div>
                    </div>

                    <img
                      src="/images/admin/06-View-Stats/server.png"
                      style={{
                        width: "275px",
                        height: "500px",
                        marginTop: "-30px",
                      }}
                      className="bg-image"
                    />
                    <div
                      className="stat"
                      style={{
                        position: "absolute",
                        maxWidth: "15rem",
                        marginTop: "-15rem",
                        //marginLeft: "-17rem",
                        marginLeft: "1rem",
                      }}
                    >
                      <div className="machine-icon-edit-profile">
                        <img src={"/images/admin/06-View-Stats/ram-icon.svg"} />
                      </div>
                      <div className="machine-title theme-bg-orange">RAM</div>
                      <div className="mid-portion" />
                      <div className="machine-subtitle theme-bg-blue">
                        {monitorData && monitorData.ram / 1024} GB
                      </div>
                    </div>

                    <img
                      src="/images/admin/06-View-Stats/server.png"
                      style={{
                        width: "275px",
                        height: "500px",
                        marginTop: "-30px",
                      }}
                      className="bg-image"
                    />
                    <div
                      className="stat"
                      style={{
                        position: "absolute",
                        maxWidth: "15rem",
                        marginTop: "-15rem",
                        //marginLeft: "18rem",
                        marginLeft: "35rem",
                      }}
                    >
                      <div className="machine-icon-edit-profile">
                        <img
                          src={
                            "/images/admin/06-View-Stats/disk-space-icon.svg"
                          }
                        />
                      </div>
                      <div className="machine-title theme-bg-orange">
                        Storage
                      </div>
                      <div className="mid-portion" />
                      <div className="machine-subtitle theme-bg-blue">
                        {monitorData && monitorData.disk_type == "hdd"
                          ? monitorData.hard_disk
                          : monitorData.disk_type == "nvme"
                          ? monitorData.nvme
                          : monitorData.ssd}
                        GB
                      </div>
                    </div>

                    <img
                      src="/images/admin/06-View-Stats/server.png"
                      style={{
                        width: "275px",
                        height: "500px",
                        marginTop: "-30px",
                      }}
                      className="bg-image"
                    />
                    <div
                      className="stat"
                      style={{
                        position: "absolute",
                        maxWidth: "15rem",
                        marginTop: "-15rem",
                        //marginLeft: "52rem",
                        marginLeft: "69rem",
                      }}
                    >
                      <div className="machine-icon-edit-profile">
                        <img
                          src={"/admin/images/admin/13-Profile/support.png"}
                        />
                      </div>
                      <div className="machine-title theme-bg-orange">
                        Server Type
                      </div>
                      <div className="mid-portion" />
                      <div className="machine-subtitle theme-bg-blue">
                        {monitorData && monitorData.support_type}
                      </div>
                    </div>
                    {/* <img
                      src="/images/admin/06-View-Stats/server.png"
                      style={{
                        width: "275px",
                        height: "500px",
                        marginTop: "-30px",
                      }}
                      className="bg-image"
                    />
                    <div
                      className="stat"
                      style={{
                        position: "absolute",
                        maxWidth: "15rem",
                        marginTop: "-15rem",
                        marginLeft: "86rem",
                      }}
                    >
                      <div className="machine-icon-edit-profile">
                        <img
                          src={"/admin/images/admin/13-Profile/support.png"}
                        />
                      </div>
                      <div
                        className="machine-title"
                        style={{
                          backgroundColor:
                            monitorData.backup_status == 1 ? "green" : "red",
                          // height: "25px",
                        }}
                      >
                        {monitorData && monitorData.backup_status == 1
                          ? "Backup ON"
                          : "Backup OFF"}
                      </div>
                      <div className="mid-portion" />
                      <div className="machine-subtitle theme-bg-blue">
                        <button
                          // className="more-details-hover"
                          onClick={() =>
                            monitorData && monitorData.backup_status == 0
                              ? handleClick()
                              : ""
                          }
                          style={{
                            color: "white",
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          {monitorData && monitorData.backup_status == 1
                            ? isHovered
                              ? "More Details"
                              : "Enable"
                            : "Disable"}
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="flip-card">
                  <div
                    className={`flip-card-inner ${isFlipped ? "flipped" : ""}`}
                  >
                    {/* Front Side */}
                    <div className="flip-card-front">
                      <div
                        style={{
                          backgroundImage: `url("/images/blue-box-bg.svg")`,
                          backgroundSize: "cover",
                          top: "8rem",
                          width: "100%",
                          padding: "30px 25px",
                          position: "relative",
                          backgroundColor: "#07528b", // Use backgroundColor instead of background
                          borderRadius: "12px",
                          // flexWrap: "wrap",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexWrap: "wrap",
                              zIndex: "1",
                            }}
                          >
                            <div
                              style={{
                                width: "50%",
                                padding: "0px 10px",
                              }}
                            >
                              {/* IP */}
                              <div
                                style={{
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  border: "2px solid white",
                                  borderRadius: "25px",
                                  padding: "5px",
                                  height: "50px",
                                }}
                              >
                                <p
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    paddingTop: "15px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  IP : {vmRes && vmRes.ip}
                                </p>
                                {vmRes && vmRes.ip && (
                                  <div className="img-wrapper">
                                    <img
                                      className="hover-zoom"
                                      src={"/images/copy_icon.png"}
                                      style={{
                                        marginLeft: "10px",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(vmRes.ip);
                                        setCopied1(true);
                                        setTimeout(
                                          () => setCopied1(false),
                                          2000
                                        );
                                      }}
                                    />
                                    {copied1 && (
                                      <span className="blinkStyle">
                                        Copied!
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                              {/* User Name */}
                              <div
                                style={{
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  border: "2px solid white",
                                  borderRadius: "25px",
                                  padding: "5px",
                                  height: "50px",
                                }}
                              >
                                <p
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    paddingTop: "15px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  UserName : {vmRes && vmRes.vm_username}
                                </p>
                                {vmRes && vmRes.vm_username && (
                                  <div className="img-wrapper">
                                    <img
                                      className="hover-zoom"
                                      src={"/images/copy_icon.png"}
                                      style={{
                                        marginLeft: "10px",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          vmRes.vm_username
                                        );
                                        setCopied2(true);
                                        setTimeout(
                                          () => setCopied2(false),
                                          2000
                                        );
                                      }}
                                    />
                                    {copied2 && (
                                      <span className="blinkStyle">
                                        Copied!
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                              {/*  */}
                            </div>
                            <div
                              style={{
                                width: "50%",
                                padding: "0px 10px",
                              }}
                            >
                              {/* SSH PORT */}
                              <div
                                style={{
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  border: "2px solid white",
                                  borderRadius: "25px",
                                  padding: "5px",
                                  height: "50px",
                                }}
                              >
                                <p
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    paddingTop: "15px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  SSH PORT : 6351
                                </p>
                                <div className="img-wrapper">
                                  <img
                                    className="hover-zoom"
                                    src={"/images/copy_icon.png"}
                                    style={{
                                      marginLeft: "10px",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(6351);
                                      setCopied3(true);
                                      setTimeout(() => setCopied3(false), 2000);
                                    }}
                                  />
                                  {copied3 && (
                                    <span className="blinkStyle">Copied!</span>
                                  )}
                                </div>
                              </div>
                              {/* Password */}
                              <div
                                style={{
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  border: "2px solid white",
                                  borderRadius: "25px",
                                  padding: "5px",
                                  height: "50px",
                                }}
                              >
                                <p
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    paddingTop: "15px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  SSH Password :{" "}
                                  {vmRes && showPass
                                    ? vmRes.vm_pass
                                    : "••••••••"}
                                </p>
                                {vmRes && vmRes.vm_pass && (
                                  <div className="img-wrapper">
                                    <img
                                      className="hover-zoom"
                                      src={"/images/copy_icon.png"}
                                      style={{
                                        marginLeft: "10px",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          vmRes.vm_pass
                                        );
                                        setCopied4(true);
                                        setTimeout(
                                          () => setCopied4(false),
                                          2000
                                        );
                                      }}
                                    />
                                    {copied4 && (
                                      <span className="blinkStyle">
                                        Copied!
                                      </span>
                                    )}
                                  </div>
                                )}
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "3%",
                                  }}
                                >
                                  {showPass ? (
                                    <FaEyeSlash
                                      onClick={() => setShowPass(false)}
                                      style={{
                                        color: "white",
                                        width: "20px",
                                      }}
                                    />
                                  ) : (
                                    <FaEye
                                      onClick={() => setShowPass(true)}
                                      style={{
                                        color: "white",
                                        width: "20px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div onClick={() => setChangePass(!changePass)}>
                            <p
                              style={{
                                marginTop: "10px",
                                color: "white",
                                textAlign: "center",
                                fontSize: "20px",
                                paddingTop: "10px",
                                marginLeft: "20px",
                                fontWeight: "600",
                                textDecoration: "underline",
                              }}
                            >
                              {" "}
                              Change Password
                            </p>
                          </div>

                          {vmRes && vmRes.vnc_status && (
                            <button
                              style={{
                                position: "absolute",
                                top:
                                  vmRes && vmRes.vnc_status !== 0
                                    ? "70%"
                                    : "45%",
                                left: "70%",
                                fontWeight: "700",
                                color: "white",
                                height: "55px",
                                width: "10rem",
                                backgroundColor: "#e97730",
                                outline: "4px solid #e97730",
                                border: "4px solid #ffff",
                                borderColor: "white",
                                borderRadius: "30px",
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.color = "#07528B")
                              } // Change color on hover
                              onMouseOut={(e) =>
                                (e.target.style.color = "white")
                              }
                              onClick={() => VNC(monitorData.vm_id)}
                            >
                              {" "}
                              <img
                                src={"/vmc-monitor.png"}
                                alt={"/vmc-monitor.png"}
                                style={{
                                  marginRight: "5px",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                              Connect VNC
                            </button>
                          )}

                          {/* Regenerate Button */}
                          <button
                            style={{
                              position: "absolute",
                              top:
                                vmRes && vmRes.vnc_status !== 0 ? "70%" : "45%",
                              left: "80%",
                              fontWeight: "700",
                              color: "white",
                              height: "55px",
                              width: "10rem",
                              backgroundColor: "#e97730",
                              outline: "4px solid #e97730",
                              border: "4px solid #ffff",
                              borderColor: "white",
                              borderRadius: "30px",
                            }}
                            onMouseOver={(e) =>
                              (e.target.style.color = "#07528B")
                            } // Change color on hover
                            onMouseOut={(e) => (e.target.style.color = "white")}
                            onClick={() => regenerateSSH(monitorData.vm_id)}
                          >
                            {" "}
                            {/* <img
                              src={"/vmc-monitor.png"}
                              alt={"/vmc-monitor.png"}
                              style={{
                                marginRight: "5px",
                                width: "30px",
                                height: "30px",
                              }}
                            /> */}
                            SSH Generate
                          </button>
                        </div>
                        {changePass && (
                          <div
                            style={{
                              marginLeft: "100px",
                              position: "relative",
                              display: "flex",
                              flexWrap: "wrap",
                              zIndex: "1",
                            }}
                          >
                            <div
                              style={{
                                width: "15rem",
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid white",
                                borderRadius: "25px",
                                padding: "5px",
                                height: "50px",
                              }}
                            >
                              <input
                                type={showNewPass ? "text" : "password"}
                                id="Password"
                                className="input-signup"
                                name="Password"
                                placeholder="New Password"
                                value={newPass}
                                style={{
                                  color: "white",
                                  border: "none",
                                  outline: "none",
                                  background: "transparent",
                                  flex: "1",
                                  padding: "5px",
                                }}
                                onChange={(e) => setNewPass(e.target.value)}
                              />
                              {showNewPass ? (
                                <FaEyeSlash
                                  onClick={() => setShowNewPass(false)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              ) : (
                                <FaEye
                                  onClick={() => setShowNewPass(true)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                marginLeft: "20px",
                                width: "15rem",
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid white",
                                borderRadius: "25px",
                                padding: "5px",
                                height: "50px",
                              }}
                            >
                              <input
                                type={showConfPass ? "text" : "password"}
                                id="Confirm Password"
                                className="input-signup"
                                name="Confirm Password"
                                placeholder="Confirm Password"
                                value={confPass}
                                style={{
                                  color: "white",
                                  border: "none",
                                  outline: "none",
                                  background: "transparent",
                                  flex: "1",
                                  padding: "5px",
                                }}
                                onChange={(e) => setConfPass(e.target.value)}
                              />
                              {showConfPass ? (
                                <FaEyeSlash
                                  onClick={() => setShowConfPass(false)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              ) : (
                                <FaEye
                                  onClick={() => setShowConfPass(true)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              )}
                            </div>
                            <div
                              className="log-in"
                              style={{
                                marginTop: "10px",
                                marginLeft: "-8rem",
                                justifyContent: "center",
                              }}
                              // onClick={() => UpdateMachinePass()}
                            >
                              <a className="media-link">
                                <div
                                  className="media-banner"
                                  style={{
                                    width: "auto",
                                    height: "50px",
                                    // marginTop: "10px",
                                    marginLeft: "10rem",

                                    position: "absolute",

                                    marginTop: "-28px",
                                  }}
                                >
                                  <img
                                    className="normal-banner"
                                    src="/images/signup-btn-bg.png"
                                    alt=""
                                    style={{
                                      marginTop: "-6px",
                                      width: "8rem",
                                      height: "4rem",
                                    }}
                                  />
                                  <img
                                    className="hover-img-banner"
                                    src="/images/search-btn-hover.png"
                                    alt="/images/search-btn-hover.png"
                                    style={{
                                      marginTop: "-6px",
                                      width: "8rem",
                                      height: "4rem",
                                    }}
                                  />
                                  <span
                                    className="login-text"
                                    style={{
                                      fontSize: "20px",
                                      color: "#07528B",
                                      marginTop: "0px",
                                    }}
                                    onClick={() => UpdateMachinePass()}
                                  >
                                    Submit
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        )}

                        {/* VNC Details */}

                        {vmRes && vmRes.vnc_status && (
                          <div style={{ marginTop: "20px" }}>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexWrap: "wrap",
                                zIndex: "1",
                              }}
                            >
                              <div
                                style={{
                                  width: "50%",
                                  padding: "0px 10px",
                                }}
                              >
                                {/* IP */}
                                <div
                                  style={{
                                    marginTop: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    border: "2px solid white",
                                    borderRadius: "25px",
                                    padding: "5px",
                                    height: "50px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "20px",
                                      paddingTop: "15px",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {" "}
                                    VNC IP : {vmRes && vmRes.vnc_ip}
                                  </p>
                                  {vmRes && vmRes.vnc_ip && (
                                    <div className="img-wrapper">
                                      <img
                                        className="hover-zoom"
                                        src={"/images/copy_icon.png"}
                                        style={{
                                          marginLeft: "10px",
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            vmRes.vnc_ip
                                          );
                                          setCopied5(true);
                                          setTimeout(
                                            () => setCopied5(false),
                                            2000
                                          );
                                        }}
                                      />
                                      {copied5 && (
                                        <span className="blinkStyle">
                                          Copied!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                                {/* User Name */}
                                <div
                                  style={{
                                    marginTop: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    border: "2px solid white",
                                    borderRadius: "25px",
                                    padding: "5px",
                                    height: "50px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "20px",
                                      paddingTop: "15px",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {" "}
                                    VNC Password :{" "}
                                    {vmRes && showVNCPass
                                      ? vmRes.vnc_pass
                                      : "••••••••"}
                                  </p>
                                  {vmRes && vmRes.vnc_pass && (
                                    <div className="img-wrapper">
                                      <img
                                        className="hover-zoom"
                                        src={"/images/copy_icon.png"}
                                        style={{
                                          marginLeft: "10px",
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            vmRes.vnc_pass
                                          );
                                          setCopied6(true);
                                          setTimeout(
                                            () => setCopied6(false),
                                            2000
                                          );
                                        }}
                                      />
                                      {copied6 && (
                                        <span className="blinkStyle">
                                          Copied!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      position: "absolute",
                                      //right: "0.5%",
                                      marginLeft: "850px",
                                    }}
                                  >
                                    {showVNCPass ? (
                                      <FaEyeSlash
                                        onClick={() => setShowVNCPass(false)}
                                        style={{
                                          color: "white",
                                          width: "20px",
                                        }}
                                      />
                                    ) : (
                                      <FaEye
                                        onClick={() => setShowVNCPass(true)}
                                        style={{
                                          color: "white",
                                          width: "20px",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                {/*  */}
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  padding: "0px 10px",
                                }}
                              >
                                {/* SSH PORT */}
                                <div
                                  style={{
                                    marginTop: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    border: "2px solid white",
                                    borderRadius: "25px",
                                    padding: "5px",
                                    height: "50px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "20px",
                                      paddingTop: "15px",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {" "}
                                    VNC PORT : {vmRes && vmRes.vnc_port}
                                  </p>
                                  {vmRes && vmRes.vnc_port && (
                                    <div className="img-wrapper">
                                      <img
                                        className="hover-zoom"
                                        src={"/images/copy_icon.png"}
                                        style={{
                                          marginLeft: "10px",
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            vmRes.vnc_port
                                          );
                                          setCopied7(true);
                                          setTimeout(
                                            () => setCopied7(false),
                                            2000
                                          );
                                        }}
                                      />
                                      {copied7 && (
                                        <span className="blinkStyle">
                                          Copied!
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div onClick={() => setChangeVNCPass(!changeVNCPass)}>
                            <p
                              style={{
                                marginTop: "10px",
                                color: "white",
                                textAlign: "center",
                                fontSize: "20px",
                                paddingTop: "10px",
                                marginLeft: "20px",
                                fontWeight: "600",
                                textDecoration: "underline",
                              }}
                            >
                              {" "}
                              Change VNC Password
                            </p>
                          </div> */}
                          </div>
                        )}

                        {/* {changeVNCPass && (
                          <div
                            style={{
                              marginLeft: "100px",
                              position: "relative",
                              display: "flex",
                              flexWrap: "wrap",
                              zIndex: "1",
                            }}
                          >
                            <div
                              style={{
                                width: "15rem",
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid white",
                                borderRadius: "25px",
                                padding: "5px",
                                height: "50px",
                              }}
                            >
                              <input
                                type={showNewPass ? "text" : "password"}
                                id="VNC Password"
                                className="input-signup"
                                name="VNC Password"
                                placeholder="VNC New Password"
                                value={newPass}
                                style={{
                                  color: "white",
                                  border: "none",
                                  outline: "none",
                                  background: "transparent",
                                  flex: "1",
                                  padding: "5px",
                                }}
                                onChange={(e) => setNewPass(e.target.value)}
                              />
                              {showNewPass ? (
                                <FaEyeSlash
                                  onClick={() => setShowNewPass(false)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              ) : (
                                <FaEye
                                  onClick={() => setShowNewPass(true)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                marginLeft: "20px",
                                width: "17rem",
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid white",
                                borderRadius: "25px",
                                padding: "5px",
                                height: "50px",
                              }}
                            >
                              <input
                                type={showConfPass ? "text" : "password"}
                                id="VNC Confirm Password"
                                className="input-signup"
                                name="VNC Confirm Password"
                                placeholder="VNC Confirm Password"
                                value={confPass}
                                style={{
                                  color: "white",
                                  border: "none",
                                  outline: "none",
                                  background: "transparent",
                                  flex: "1",
                                  padding: "5px",
                                }}
                                onChange={(e) => setConfPass(e.target.value)}
                              />
                              {/* {showConfPass ? (
                                <FaEyeSlash
                                  onClick={() => setShowConfPass(false)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              ) : (
                                <FaEye
                                  onClick={() => setShowConfPass(true)}
                                  style={{ color: "white", width: "20px" }}
                                />
                              )} }
                            </div>
                            <div
                              className="log-in"
                              style={{
                                marginTop: "10px",
                                marginLeft: "-8rem",
                                justifyContent: "center",
                              }}
                              // onClick={() => UpdateMachinePass()}
                            >
                              <a className="media-link">
                                <div
                                  className="media-banner"
                                  style={{
                                    width: "auto",
                                    height: "50px",
                                    // marginTop: "10px",
                                    marginLeft: "10rem",

                                    position: "absolute",

                                    marginTop: "-28px",
                                  }}
                                >
                                  <img
                                    className="normal-banner"
                                    src="/images/signup-btn-bg.png"
                                    alt=""
                                    style={{
                                      marginTop: "-6px",
                                      width: "8rem",
                                      height: "4rem",
                                    }}
                                  />
                                  <img
                                    className="hover-img-banner"
                                    src="/images/search-btn-hover.png"
                                    alt="/images/search-btn-hover.png"
                                    style={{
                                      marginTop: "-6px",
                                      width: "8rem",
                                      height: "4rem",
                                    }}
                                  />
                                  <span
                                    className="login-text"
                                    style={{
                                      fontSize: "20px",
                                      color: "#07528B",
                                      marginTop: "0px",
                                    }}
                                    onClick={() => UpdateMachinePass()}
                                  >
                                    Submit
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        )} */}
                      </div>

                      {/* Back Side */}
                      {/* <div
                        className="flip-card-back"
                        style={{ backgroundColor: "white" }}
                      >
                        <div
                          className="stat"
                          style={
                            {
                              //maxWidth: "15rem",
                              //marginTop: "20px",
                            }
                          }
                        >
                          <button
                            style={{
                              position: "absolute",
                              //top: "9%",
                              left: "90%",
                              fontWeight: "700",
                              color: "white",
                              height: "55px",
                              width: "7rem",
                              backgroundColor: "#e97730",
                              outline: "4px solid #e97730",
                              border: "4px solid #ffff",
                              borderColor: "white",
                              borderRadius: "30px",
                            }}
                            onMouseOver={(e) =>
                              (e.target.style.color = "#07528B")
                            } // Change color on hover
                            onMouseOut={(e) => (e.target.style.color = "white")}
                            onClick={() => ""}
                          >
                            Reload
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      )}
      <div className="apptoast-align">
        <Toaster
          position={isMobile ? "top-center" : "bottom-right"}
          reverseOrder={false}
        />
      </div>
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
          {/* <Spinner animation="border" /> */}
        </div>
      )}
    </div>
  );
};

export default MachineStatus;
