import React, { useEffect, useState, useRef } from "react";

import { Col, Container, Row, Button } from "react-bootstrap";
import "./BillingPage.css";
import instance, {
  apiDecrypteRequest,
  apiEncryptRequest,
  currencyReturn,
  currencyReturnOnlyAmount,
  decryptData,
  getCurrencySymbol,
} from "../../Api";
import { useAuth } from "../../AuthContext";
import Loader from "../common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";

const BillReport = () => {
  const { smuser, appCurrency } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }

  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [billingData, setBillingData] = useState([]);
  const [allVMS, setAllVMS] = useState(null);
  const [cdnBillingData, setCDNBillingData] = useState([]);
  const [allCDN, setAllCDN] = useState([]);
  const [activeId, setActiveID] = useState(null);
  const billData = location.state ? location.state.billData : null;

  const [activeButton, setActiveButton] = useState("Server");
  const tabs = ["Server", "CDN"];

  const [showvm, setShowvm] = useState("all");
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedVM, setSelectedVM] = useState(null);
  const dropdownRef = useRef(null);

  const [visibleCDN, setVisibleCDN] = useState(false);
  const [showCDN, setShowCDN] = useState("all");
  const [searchTextCDN, setSearchTextCDN] = useState("");
  const [selectedCDN, setSelectedCDN] = useState(null);

  useEffect(() => {
    if (billData === null) {
      navigate(-1);
    } else {
      setActiveID(billData.vm_id);
      console.log(billData, ".........BillingCall");
      BillingCall();
    }
    //
    // BillingCall();
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    // document.addEventListener("mousedown", handleClick, false);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      // document.removeEventListener("mousedown", handleClick, false);
    };
  }, [isMobile]);

  const BillingCall = async () => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: billData.vm_id,
      cdn_id: billData.id,
    };
    //console.log(payload, "CDNPAY");
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const loginUserResponse = await instance.post(
        "/bill/bill_reports",
        encryptedResponse
      );
      //console.log(loginUserResponse.data, "====loginUserResponse");

      // // Third API call to decrypt the login response
      const loginResponse = await decryptData(loginUserResponse.data);
      const bills = loginResponse.bills;
      const cdnbills = loginResponse.cdnbills;
      const vms = loginResponse.vms;
      const cdn = loginResponse.cdn;

      console.log(loginResponse, "==!==!==/bill/bill_reports");

      if (vms !== null) {
        const vmArray = Object.keys(vms).map((key) => vms[key]);
        setAllVMS(vmArray);
      }

      if (cdn !== null) {
        const cdnArray = Object.keys(cdn).map((key) => cdn[key]);
        setAllCDN(cdnArray);
        console.log(cdnArray, "==!==!==cdnArray");
      }

      const billsArray = Object.keys(bills).map((key) => bills[key]);
      setBillingData(billsArray);

      setCDNBillingData(cdnbills);

      setSearchText(billData.vm_name);

      // const userDetails = loginResponse;
      // const user = loginResponse.user;
      // const vm = loginResponse.vm;

      // const vmArray = Object.keys(vm).map((key) => vm[key]);
      // //console.log(vmArray, "==!==!==vvmArraym");
      // setBillingData(vmArray);
      // const vmArray = Object.keys(vm).map((key) => vm[key]);
      // localStorage.setItem("NEW_USER", JSON.stringify(userDetails));
      // window.location.href = "/";
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const handleChangeMachine = (value) => {
    if (activeId !== null) {
      setBillingData(null);
      UpdateTable();
    }
  };

  const UpdateTable = async () => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      vm_id: activeId,
    };
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const loginUserResponse = await instance.post(
        "/bill/bill_reports",
        encryptedResponse
      );
      // console.log(loginUserResponse.data, "====/bill/bill_reports");

      // // Third API call to decrypt the login response
      const loginResponse = await decryptData(loginUserResponse.data);
      console.log(loginResponse, "==!==!==/bill/bill_reports");
      const bills = loginResponse.bills;
      setBillingData(bills);
      const vms = loginResponse.vms;
      const vmArray = Object.keys(vms).map((key) => vms[key]);
      //console.log(bills, "==!==!==bills");
      //console.log(vmArray, "==!==!==vmArray");
      setAllVMS(vmArray);
      // vms
      //
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClick = (e) => {
    // if (dropdownRef.current.contains(e.target)) {
    //   return;
    // }
    if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      return;
    }
    setVisible(false);
    setVisibleCDN(false);
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      setShowvm("all");
    }
    if (!visible) {
      setVisible(true);
    }
  };

  const handleChangeCDN = (e) => {
    setSearchTextCDN(e.target.value);
    if (e.target.value === "") {
      setShowCDN("all");
    }
    if (!visibleCDN) {
      setVisibleCDN(true);
    }
  };

  const selectItem = (item) => {
    setSearchText(item.vm_name);
    setSelectedVM(item.vm_id);
    setShowvm(item.vm_id);
    setVisible(false);
  };

  const selectItemCDN = (item) => {
    setSearchTextCDN(item.website_url);
    setSelectedCDN(item.id);
    setShowCDN(item.id);
    setVisibleCDN(false);
  };

  const searchFilter = (searchValue, list, searchBy = "vm_name") => {
    let lowerCaseQuery = searchValue.toLowerCase();
    let filteredList = searchValue
      ? list.filter((x) => x[searchBy].toLowerCase().includes(lowerCaseQuery))
      : list;
    return filteredList;
  };

  const searchFilterCDN = (searchValue, list, searchBy = "website_url") => {
    let lowerCaseQuery = searchValue.toLowerCase();
    let filteredList = searchValue
      ? list.filter((x) => x[searchBy].toLowerCase().includes(lowerCaseQuery))
      : list;
    return filteredList;
  };

  const featureListStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    padding: "20px",
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "65rem",
        position: "relative",
        backgroundImage: isMobile ? `url(./main-bg.jpg)` : `url(./main-bg.jpg)`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundColor: "#141414",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      {isMobile ? (
        <div className="" style={{ height: "50rem" }}>
          <div className="heading-dotted-bill">
            VM Billing List <span></span>
          </div>
          <div className="features-section-solution">
            <div
              style={{
                position: "relative",
                backgroundColor: "#e97730",
                padding: "18px",
                borderRadius: "20px",
                zIndex: "999",
                width: "80%",
              }}
            >
              <div
                style={{
                  // display: "flex",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                <select
                  name="plan_time"
                  style={{
                    borderRadius: "30px",
                    marginRight: "10px",
                    padding: "10px 15px",
                    border: "2px solid #e97730",
                    width: "17rem",
                    marginRight: "15px",
                  }}
                  // value={activeId}
                  onChange={(e) => {
                    setActiveID(e.target.value);
                    handleChangeMachine(e.target.value);
                    ////console.log(e.target.value);
                  }}
                >
                  {/* <option value={activeId}>Select</option> */}
                  {allVMS &&
                    allVMS.map((item, index) => (
                      <option key={index} value={item.vm_id}>
                        {item.vm_name}
                      </option>
                    ))}
                </select>
                <div className="search-form" style={{ marginTop: "10px" }}>
                  <div
                    className="input-container-notif"
                    style={{ width: "16rem" }}
                  >
                    {/* <FaCalendar style={{ color: "white" }} /> */}
                    <input
                      //   value={email}
                      type="date"
                      name="from"
                      className="input-signup"
                      placeholder="From"
                      //   onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="input-container-notif"
                    style={{ width: "16rem" }}
                  >
                    {/* <FaCalendar style={{ color: "white" }} /> */}
                    <input
                      //   value={email}
                      type="date"
                      name="to"
                      className="input-signup"
                      placeholder="To"
                      //   style={{color:'white'}}
                      //   onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <input type="hidden" id="getval" value="All" />
                  <div className="button-fields">
                    <div
                      className="button-cont"
                      style={{
                        marginTop: "-11px",
                      }}
                    >
                      <input
                        type="submit"
                        name="submit"
                        value="Submit"
                        className="input-submit new-btn"
                        // onClick={submitData}
                      />
                    </div>
                    <div
                      className="button-cont"
                      style={{
                        marginTop: "-11px",
                        // marginRight: "10px",
                      }}
                    >
                      <input
                        type="export"
                        name="export"
                        value="Export"
                        className="input-submit new-btn-export"
                        style={{
                          paddingLeft: "23px",
                          fontWeight: "700",
                          color: "#154e7a",
                          backgroundColor: "white",
                          marginLeft: "10px",
                          width: "6rem",
                          /* margin-top: 35px; */
                          borderRadius: "50px",
                          /* padding: 10px 30px; */
                          border: "1px solid rgb(255, 255, 255);",
                        }}
                        // onClick={submitData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  marginTop: "2rem",
                  maxWidth: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <div className="billing-list">
                  <div className="table-row no-hover">
                    <div className="table-head">
                      <div className="table-content">
                        <div>Action Performed</div>
                        <div style={{ fontSize: "14px" }}>Action Time</div>
                      </div>
                      <img
                        src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                        className="bg-image"
                        alt="Background"
                      />
                    </div>
                    <div className="table-head">
                      <div className="table-content">
                        <div>Previous Action</div>
                        <div style={{ fontSize: "14px" }}>Action Time</div>
                      </div>
                      <img
                        src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                        className="bg-image"
                        alt="Background"
                      />
                    </div>
                    <div className="table-head">
                      <div className="table-content">
                        <div>Previous Configuration</div>
                        <div style={{ fontSize: "14px" }}>RAM/DISK/vCPU</div>
                      </div>
                      <img
                        src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                        className="bg-image"
                        alt="Background"
                      />
                    </div>
                    <div className="table-head">
                      <div className="table-content">
                        <div>Present Configuration</div>
                        <div style={{ fontSize: "14px" }}>RAM/DISK/vCPU</div>
                      </div>
                      <img
                        src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                        className="bg-image"
                        alt="Background"
                      />
                    </div>

                    <div className="table-head">
                      <div className="table-content">
                        <div>Cost</div>
                        <div style={{ fontSize: "14px" }}>
                          (in {smuser && smuser.prefer_currency}) (
                          {getCurrencySymbol(smuser.prefer_currency)})
                        </div>
                      </div>
                      <img
                        src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                        className="bg-image"
                        alt="Background"
                      />
                    </div>
                  </div>
                  {/* .filter((item) => item.wallet_comes !== null) */}
                  {billingData &&
                    billingData.map((item, idx) => (
                      <div className="table-row">
                        <div className="table-data">
                          <div className="table-content">
                            <div>
                              {item.vm_action}
                              {/* {item.bill_type === "recharge"
                                ? "RECHARGE"
                                : item.vm_action === "CREATE"
                                ? "CREATE"
                                : null} */}
                            </div>
                            <div style={{ fontSize: "14px" }}>
                              {item.action_time}
                            </div>
                            <div style={{ fontSize: "14px" }}>
                              {item.vm_action === "DELETE"
                                ? "(added to wallet)"
                                : ""}
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-data">
                          <div className="table-content">
                            <div>
                              {item.vm_action}
                              {item.vm_action === "DELETE" ? "1" : ""}
                              {/* {item.bill_type === "recharge"
                                ? "RECHARGE"
                                : item.vm_action === "DELETE"
                                ? "DELETE"
                                : null} */}
                            </div>
                            <div style={{ fontSize: "14px" }}>
                              {item.created_at}
                            </div>
                            <div style={{ fontSize: "14px" }}>
                              {item.vm_action === "DELETE"
                                ? "(added to wallet)"
                                : ""}
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-data">
                          <div className="table-content">
                            {item.ram / 1024} GB /{" "}
                            {item.disk_type == "hdd"
                              ? item.hard_disk
                              : item.disk_type == "ssd"
                              ? item.ssd
                              : item.nvme}{" "}
                            GB / {item.cpu} CPU(s)
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-data">
                          <div className="table-content">
                            <div>
                              {item.ram / 1024} GB /{" "}
                              {item.disk_type == "hdd"
                                ? item.hard_disk
                                : item.disk_type == "ssd"
                                ? item.ssd
                                : item.nvme}{" "}
                              GB / {item.cpu} CPU(s)
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        {/* <div className="table-data">
                            <div className="table-content">
                               {item.ram / 1024} GB / {item.hard_disk} GB /{" "}
                              {item.cpu} CPU(s) 
                            </div>
                            <img
                              src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                              className="bg-image"
                              alt="Background"
                            />
                          </div> */}
                        <div className="table-data">
                          <div className="table-content">
                            <div>
                              <strong>
                                <i
                                  className="fas fa-rupee-sign"
                                  style={{ paddingTop: "3px" }}
                                  aria-hidden="true"
                                ></i>
                                <span>
                                  -
                                  {appCurrency &&
                                    smuser &&
                                    currencyReturnOnlyAmount({
                                      price:
                                        item.vm_action == "DELETE"
                                          ? item.wallet_comes
                                          : item.linode_rate,
                                      symbol: smuser.prefer_currency,
                                      rates: appCurrency,
                                    })}
                                  {/* currencyReturn({price: "userNative_credit",symbol: "smuser.prefer_currency",rates: "appCurrency"}) */}
                                  {/* <FaRupeeSign />
                                    {appCurrency &&
                                      item.cost / parseFloat(appCurrency)}{" "}
                                    /- */}
                                </span>
                              </strong>
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                      </div>
                    ))}

                  <div className="table-row no-hover">
                    <div className="table-foot">
                      <img
                        src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                        className="table-footer-image-size"
                        alt="Background"
                      />
                    </div>
                    <div className="table-foot">
                      <img
                        src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                        className="table-footer-image-size"
                        alt="Background"
                      />
                    </div>
                    <div className="table-foot">
                      <img
                        src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                        className="table-footer-image-size"
                        alt="Background"
                      />
                    </div>
                    <div className="table-foot">
                      <img
                        src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                        className="table-footer-image-size"
                        alt="Background"
                      />
                    </div>
                    {/* <div className="table-foot">
                        <img
                          src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                          className="table-footer-image-size"
                          alt="Background"
                        />
                      </div> */}
                    <div className="table-foot">
                      <img
                        src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                        className="table-footer-image-size"
                        alt="Background"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="features-page-solution"
          style={{ height: "50rem", paddingLeft: "7rem", paddingTop: "4rem" }}
        >
          <div className="heading-dotted-bill">
            VM Billing List <span></span>
          </div>
          <div
            style={{
              position: "absolute",
              marginLeft: "9rem",
              marginTop: "1rem",
            }}
          >
            {tabs.map((title, idx) => (
              <Button
                key={idx}
                style={{
                  background: `${
                    activeButton === title ? "#f47c20" : "#035189"
                  }`,
                  border: "none",
                  fontSize: "20px",
                  padding: "5px 15px",
                  color: "#fff",
                  fontWeight: "600",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  setActiveButton(title);
                  // if (activeButton === "Server") {
                  //   setCDNID(null);
                  // }
                  // if (activeButton === "CDN") {
                  //   setVmID(null);
                  // }
                }}
              >
                {title}
              </Button>
            ))}
          </div>

          {activeButton === "Server" && (
            <div className="features-section-solution">
              <Row>
                <div
                  style={{
                    marginLeft: "9rem",
                    marginTop: "60px",
                    position: "relative",
                    backgroundColor: "#e97730",
                    padding: "18px",
                    borderRadius: "20px",
                    zIndex: "999",
                    width: "70%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      zIndex: "1",
                    }}
                  >
                    {/* <select
                      name="plan_time"
                      style={{
                        borderRadius: "30px",
                        marginRight: "10px",
                        padding: "10px 15px",
                        border: "2px solid #e97730",
                        width: "15rem",
                        marginRight: "15px",
                      }}
                      // value={activeId}
                      onChange={(e) => {
                        setActiveID(e.target.value);
                        handleChangeMachine(e.target.value);
                        //console.log(e.target.value);
                      }}
                    >
                      {/* <option value={activeId}>Select</option> }
                      {allVMS &&
                        allVMS.map((item, index) => (
                          <option key={index} value={item.vm_id}>
                            {item.vm_name}
                          </option>
                        ))}
                    </select> */}

                    <input
                      className="input"
                      type="text"
                      // placeholder="Search Server"
                      value={searchText}
                      // onChange={handleChange}
                      style={{
                        borderRadius: "15px",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                      }}
                      // onFocus={() => {
                      //   // if (searchValue) {
                      //   setVisible(true);
                      //   // };
                      // }}
                    />

                    <div
                      className="search-form"
                      style={{ marginTop: "10px", marginLeft: "20px" }}
                    >
                      <div className="input-container-notif">
                        {/* <FaCalendar style={{ color: "white" }} /> */}
                        <input
                          //   value={email}
                          type="date"
                          name="from"
                          className="input-signup"
                          placeholder="From"
                          //   onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="input-container-notif">
                        {/* <FaCalendar style={{ color: "white" }} /> */}
                        <input
                          //   value={email}
                          type="date"
                          name="to"
                          className="input-signup"
                          placeholder="To"
                          //   style={{color:'white'}}
                          //   onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <input type="hidden" id="getval" value="All" />
                      <div className="button-fields">
                        <div
                          className="button-cont"
                          style={{
                            marginTop: "-11px",
                          }}
                        >
                          <input
                            type="submit"
                            name="submit"
                            value="Submit"
                            className="input-submit new-btn"
                            // onClick={submitData}
                          />
                        </div>
                        <div
                          className="button-cont"
                          style={{
                            marginTop: "-11px",
                            // marginRight: "10px",
                          }}
                        >
                          <input
                            type="export"
                            name="export"
                            value="Export"
                            className="input-submit new-btn-export"
                            style={{
                              paddingLeft: "23px",
                              fontWeight: "700",
                              color: "#154e7a",
                              backgroundColor: "white",
                              marginLeft: "10px",
                              width: "6rem",
                              /* margin-top: 35px; */
                              borderRadius: "50px",
                              /* padding: 10px 30px; */
                              border: "1px solid rgb(255, 255, 255);",
                            }}
                            // onClick={submitData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    // ref={dropdownRef}
                    className={`dropdown ${visible ? "v" : ""}`}
                  >
                    {visible && (
                      <ul>
                        {/* {!allVMS && (
                          <li key="zxc" className="dropdown_item">
                            no result
                          </li>
                        )} */}

                        {/* {allVMS &&
                          searchFilter(searchText, allVMS).map((x) => (
                            <option
                              key={x.vm_id}
                              // value={x.vm_id}
                              // onClick={() => selectItem(x)}
                              className="dropdown_item"
                            > */}
                        {/* <option key={index} value={item.vm_id}>
                              {item.vm_name}
                            </option> */}
                        <div className="item_text1">{billData.vm_name}</div>
                        {/* </option>
                          ))} */}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      marginLeft: "8rem",
                      marginTop: "2rem",
                      maxWidth: "100%",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div className="billing-list">
                      <div className="table-row no-hover">
                        <div className="table-head">
                          <div className="table-content">
                            <div>Action Performed</div>
                            <div style={{ fontSize: "14px" }}>Action Time</div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-head">
                          <div className="table-content">
                            <div>Previous Action</div>
                            <div style={{ fontSize: "14px" }}>Action Time</div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-head">
                          <div className="table-content">
                            <div>Previous Configuration</div>
                            <div style={{ fontSize: "14px" }}>
                              RAM/DISK/vCPU
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-head">
                          <div className="table-content">
                            <div>Present Configuration</div>
                            <div style={{ fontSize: "14px" }}>
                              RAM/DISK/vCPU
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>

                        <div className="table-head">
                          <div className="table-content">
                            <div>Cost</div>
                            <div style={{ fontSize: "14px" }}>
                              (in {smuser && smuser.prefer_currency}) (
                              {getCurrencySymbol(smuser.prefer_currency)})
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                      </div>
                      {/* .filter((item) => item.wallet_comes !== null) */}
                      {billingData &&
                        billingData.map((item, idx) => (
                          <div className="table-row">
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  {item.vm_action}
                                  {/* {item.bill_type === "recharge"
                                  ? "RECHARGE"
                                  : item.vm_action === "CREATE"
                                  ? "CREATE"
                                  : null} */}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.action_time}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.vm_action === "DELETE"
                                    ? "(added to wallet)"
                                    : ""}
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  {item.vm_action}

                                  {/* {item.bill_type === "recharge"
                                  ? "RECHARGE"
                                  : item.vm_action === "DELETE"
                                  ? "DELETE"
                                  : null} */}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.created_at}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.vm_action === "DELETE"
                                    ? "(added to wallet)"
                                    : ""}
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            <div className="table-data">
                              <div className="table-content">
                                {/* {item.ram / 1024} GB /{" "}
                              {item.disk_type == "hdd"
                                ? item.hard_disk
                                : item.disk_type == "ssd"
                                ? item.ssd
                                : item.nvme}{" "}
                              GB / {item.cpu} vCPU(s) */}
                                {item.ram / 1024} GB /{" "}
                                {item.disk_type == "hdd"
                                  ? item.hard_disk
                                  : item.disk_type == "ssd"
                                  ? item.ssd
                                  : item.nvme}{" "}
                                GB / {item.cpu} vCPU
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  {item.ram / 1024} GB /{" "}
                                  {item.disk_type == "hdd"
                                    ? item.hard_disk
                                    : item.disk_type == "ssd"
                                    ? item.ssd
                                    : item.nvme}{" "}
                                  GB / {item.cpu} vCPU
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            {/* <div className="table-data">
                            <div className="table-content">
                               {item.ram / 1024} GB / {item.hard_disk} GB /{" "}
                              {item.cpu} CPU(s) 
                            </div>
                            <img
                              src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                              className="bg-image"
                              alt="Background"
                            />
                          </div> */}
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  <strong>
                                    <i
                                      className="fas fa-rupee-sign"
                                      style={{ paddingTop: "3px" }}
                                      aria-hidden="true"
                                    ></i>
                                    <span>
                                      {item.vm_action == "DELETE" ? "+ " : "- "}

                                      {appCurrency &&
                                        smuser &&
                                        currencyReturnOnlyAmount({
                                          price:
                                            item.vm_action == "DELETE"
                                              ? item.wallet_comes
                                              : item.linode_rate,
                                          symbol: smuser.prefer_currency,
                                          rates: appCurrency,
                                        })}
                                      {/* currencyReturn({price: "userNative_credit",symbol: "smuser.prefer_currency",rates: "appCurrency"}) */}
                                      {/* <FaRupeeSign />
                                    {appCurrency &&
                                      item.cost / parseFloat(appCurrency)}{" "}
                                    /- */}
                                    </span>
                                  </strong>
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                          </div>
                        ))}

                      <div className="table-row no-hover">
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        {/* <div className="table-foot">
                        <img
                          src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                          className="table-footer-image-size"
                          alt="Background"
                        />
                      </div> */}
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          )}

          {activeButton === "CDN" && (
            <div className="features-section-solution">
              <Row>
                <div
                  style={{
                    marginLeft: "9rem",
                    marginTop: "60px",
                    position: "relative",
                    backgroundColor: "#e97730",
                    padding: "18px",
                    borderRadius: "20px",
                    zIndex: "999",
                    width: "70%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      zIndex: "1",
                    }}
                  >
                    {/* <select
                      name="plan_time"
                      style={{
                        borderRadius: "30px",
                        marginRight: "10px",
                        padding: "10px 15px",
                        border: "2px solid #e97730",
                        width: "15rem",
                        marginRight: "15px",
                      }}
                      // value={activeId}
                      onChange={(e) => {
                        //setActiveID(e.target.value);
                        //handleChangeMachine(e.target.value);
                        //console.log(e.target.value);
                      }}
                    >
                      {/* <option value={activeId}>Select</option> */}
                    {/* {allCDN &&
                        allCDN.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.website_url}
                          </option>
                        ))} }
                    </select> */}

                    <input
                      className="input"
                      type="text"
                      // placeholder="Search Website"
                      value={searchTextCDN}
                      // onChange={handleChangeCDN}
                      style={{ borderRadius: "15px", marginLeft: "10px" }}
                      // onFocus={() => {
                      //   // if (searchValue) {
                      //   setVisibleCDN(true);
                      //   // };
                      // }}
                    />

                    <div
                      className="search-form"
                      style={{ marginTop: "10px", marginLeft: "20px" }}
                    >
                      <div className="input-container-notif">
                        {/* <FaCalendar style={{ color: "white" }} /> */}
                        <input
                          //   value={email}
                          type="date"
                          name="from"
                          className="input-signup"
                          placeholder="From"
                          //   onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="input-container-notif">
                        {/* <FaCalendar style={{ color: "white" }} /> */}
                        <input
                          //   value={email}
                          type="date"
                          name="to"
                          className="input-signup"
                          placeholder="To"
                          //   style={{color:'white'}}
                          //   onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <input type="hidden" id="getval" value="All" />
                      <div className="button-fields">
                        <div
                          className="button-cont"
                          style={{
                            marginTop: "-11px",
                          }}
                        >
                          <input
                            type="submit"
                            name="submit"
                            value="Submit"
                            className="input-submit new-btn"
                            // onClick={submitData}
                          />
                        </div>
                        <div
                          className="button-cont"
                          style={{
                            marginTop: "-11px",
                            // marginRight: "10px",
                          }}
                        >
                          <input
                            type="export"
                            name="export"
                            value="Export"
                            className="input-submit new-btn-export"
                            style={{
                              paddingLeft: "23px",
                              fontWeight: "700",
                              color: "#154e7a",
                              backgroundColor: "white",
                              marginLeft: "10px",
                              width: "6rem",
                              /* margin-top: 35px; */
                              borderRadius: "50px",
                              /* padding: 10px 30px; */
                              border: "1px solid rgb(255, 255, 255);",
                            }}
                            // onClick={submitData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    ref={dropdownRef}
                    className={`dropdown ${visibleCDN ? "v" : ""}`}
                  >
                    {visibleCDN && (
                      <ul>
                        {!allCDN && (
                          <li key="zxc" className="dropdown_item">
                            no result
                          </li>
                        )}

                        {allCDN &&
                          searchFilterCDN(searchTextCDN, allCDN).map((x) => (
                            <option
                              key={x.id}
                              // value={x.vm_id}
                              onClick={() => selectItemCDN(x)}
                              className="dropdown_item"
                            >
                              {/* <option key={index} value={item.vm_id}>
                              {item.vm_name}
                            </option> */}
                              <div className="item_text1">{x.website_url}</div>
                            </option>
                          ))}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      marginLeft: "8rem",
                      marginTop: "2rem",
                      maxWidth: "100%",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div className="billing-list">
                      <div className="table-row no-hover">
                        <div className="table-head">
                          <div className="table-content">
                            <div>Action Performed</div>
                            <div style={{ fontSize: "14px" }}>Action Time</div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-head">
                          <div className="table-content">
                            <div>Previous Action</div>
                            <div style={{ fontSize: "14px" }}>Action Time</div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-head">
                          <div className="table-content">
                            <div>Total Data</div>
                            {/* <div style={{ fontSize: "14px" }}>
                              RAM/DISK/vCPU
                            </div> */}
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                        <div className="table-head">
                          <div className="table-content">
                            <div>Remain Data</div>
                            {/* <div style={{ fontSize: "14px" }}>
                              RAM/DISK/vCPU
                            </div> */}
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>

                        <div className="table-head">
                          <div className="table-content">
                            <div>Cost</div>
                            <div style={{ fontSize: "14px" }}>
                              (in {smuser && smuser.prefer_currency}) (
                              {getCurrencySymbol(smuser.prefer_currency)})
                            </div>
                          </div>
                          <img
                            src="/images/admin/08-VM-Billing-List/server-top-img.svg"
                            className="bg-image"
                            alt="Background"
                          />
                        </div>
                      </div>
                      {/* .filter((item) => item.wallet_comes !== null) */}
                      {cdnBillingData &&
                        cdnBillingData.map((item, idx) => (
                          <div className="table-row">
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  {item.vm_action}
                                  {/* {item.bill_type === "recharge"
                                  ? "RECHARGE"
                                  : item.vm_action === "CREATE"
                                  ? "CREATE"
                                  : null} */}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.action_time}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.vm_action === "DELETE"
                                    ? "(added to wallet)"
                                    : ""}
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  {item.vm_action}

                                  {/* {item.bill_type === "recharge"
                                  ? "RECHARGE"
                                  : item.vm_action === "DELETE"
                                  ? "DELETE"
                                  : null} */}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.created_at}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {item.vm_action === "DELETE"
                                    ? "(added to wallet)"
                                    : ""}
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            <div className="table-data">
                              <div className="table-content">
                                {/* {item.ram / 1024} GB /{" "}
                              {item.disk_type == "hdd"
                                ? item.hard_disk
                                : item.disk_type == "ssd"
                                ? item.ssd
                                : item.nvme}{" "}
                              GB / {item.cpu} vCPU(s) */}
                                {item.cdn_datatransfer} TB{" "}
                                {/* {item.disk_type == "hdd"
                                  ? item.hard_disk
                                  : item.disk_type == "ssd"
                                  ? item.ssd
                                  : item.nvme}{" "}
                                GB / {item.cpu} vCPU */}
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  {item.remaining_data} TB{" "}
                                  {/* {item.disk_type == "hdd"
                                    ? item.hard_disk
                                    : item.disk_type == "ssd"
                                    ? item.ssd
                                    : item.nvme}{" "}
                                  GB / {item.cpu} vCPU */}
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                            {/* <div className="table-data">
                            <div className="table-content">
                               {item.ram / 1024} GB / {item.hard_disk} GB /{" "}
                              {item.cpu} CPU(s) 
                            </div>
                            <img
                              src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                              className="bg-image"
                              alt="Background"
                            />
                          </div> */}
                            <div className="table-data">
                              <div className="table-content">
                                <div>
                                  <strong>
                                    <i
                                      className="fas fa-rupee-sign"
                                      style={{ paddingTop: "3px" }}
                                      aria-hidden="true"
                                    ></i>
                                    <span>
                                      {item.vm_action == "DELETE" ? "+ " : "- "}

                                      {appCurrency &&
                                        smuser &&
                                        currencyReturnOnlyAmount({
                                          price:
                                            item.vm_action == "DELETE"
                                              ? item.wallet_comes
                                              : item.linode_rate,
                                          symbol: smuser.prefer_currency,
                                          rates: appCurrency,
                                        })}
                                      {/* currencyReturn({price: "userNative_credit",symbol: "smuser.prefer_currency",rates: "appCurrency"}) */}
                                      {/* <FaRupeeSign />
                                    {appCurrency &&
                                      item.cost / parseFloat(appCurrency)}{" "}
                                    /- */}
                                    </span>
                                  </strong>
                                </div>
                              </div>
                              <img
                                src="/images/admin/08-VM-Billing-List/server-middle-img.svg"
                                className="bg-image"
                                alt="Background"
                              />
                            </div>
                          </div>
                        ))}

                      <div className="table-row no-hover">
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                        {/* <div className="table-foot">
                        <img
                          src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                          className="table-footer-image-size"
                          alt="Background"
                        />
                      </div> */}
                        <div className="table-foot">
                          <img
                            src="/images/admin/09-Billing-Table/server-bottom-bg.svg"
                            className="table-footer-image-size"
                            alt="Background"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
          {/* <Spinner animation="border" /> */}
        </div>
      )}
    </div>
  );
};

export default BillReport;
