import React, { useState } from "react";
import "./ProfilePopUp.css"; // Make sure to create this CSS file
import { FaLock, FaSignOutAlt, FaUser } from "react-icons/fa";
import { useAuth } from "../../AuthContext";

const ProfilePopUp = (props) => {
  const { logout, smuser } = useAuth();
  const { type, wallet } = props;
  // console.log(wallet, "wallet....");
  const logoutFunc = () => {
    try {
      logout();
      // window.location.href = "/";
    } catch (error) {
      console.error("Error occurred during logout:", error);
    }
  };

  return (
    <div
      className="main-view-popup"
      style={{
        backgroundImage: "url('/admin/images/admin/02-vm/gray-box-bg.png')",
        height: type !== "profile" ? "15rem" : "20rem",
      }}
    >
      {type === "profile" ? (
        <>
          <div
            style={{
              display: "grid",
              justifyItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              className=""
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                // padding: "10px",
                outline: "2px solid #035189",
                border: "5px solid white",
              }}
            >
              <div className="">
                {console.log(smuser.image, "IMAGE")}
                <img
                  // src={`https://console.upnetcloud.com/${
                  //   smuser && smuser.image
                  // }`}
                  src={`${smuser && smuser.image}`}
                  style={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "50%",
                    outline: "2px solid #035189",
                    border: "5px solid white",
                  }}
                />
              </div>
              <div className="profile-badge">{smuser.email}</div>
            </div>
          </div>

          <ul style={{ marginLeft: "30px" }}>
            <li>
              <a href="/edit-profile" className="profile-menuitem">
                <FaUser
                  style={{ width: "20px", height: "20px", paddingRight: "5px" }}
                />
                Profile
              </a>
            </li>
            <li>
              <a href="/change-password" className="profile-menuitem">
                <FaLock
                  style={{ width: "20px", height: "20px", paddingRight: "5px" }}
                />
                Change Password
              </a>
            </li>
            <li onClick={logoutFunc}>
              <a className="profile-menuitem">
                <FaSignOutAlt
                  style={{ width: "30px", height: "30px", paddingRight: "5px" }}
                />{" "}
                Logout
              </a>
            </li>
          </ul>
        </>
      ) : (
        <div>
          <div>
            <a href="/wallet">
              <h5
                style={{
                  textAlign: "center",
                  padding: "18px",
                  color: "#154e7a",
                }}
              >
                Total Balance <br />({wallet})
              </h5>
            </a>
            <a href="/wallet">
              <h5
                style={{
                  textAlign: "center",
                  padding: "18px",
                  color: "#154e7a",
                }}
              >
                Total Balance <br />({wallet})
              </h5>
            </a>
          </div>
          <ul>
            <li style={{ marginTop: "10px", marginLeft: "25px" }}>
              <a
                href="/wallet"
                className="profile-menuitem"
                style={{ fontSize: "21px" }}
              >
                <FaLock
                  style={{ width: "20px", height: "20px", paddingRight: "5px" }}
                />
                My Wallet
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfilePopUp;
