import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useHistory,
  useLocation,
} from "react-router-dom";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import Home from "./Components/Home";

import Header from "./Components/Header";
import { useEffect, useState } from "react";

import { UserProvider } from "./UserContext";
// import ClientJS from "clientjs";
// import { generateToken, messaging } from "./firebase";

// import firebaseApp from "./firebase";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import SolutionsPage from "./Components/SolutionsPage";
import Blog from "./Components/Blog";
import ProductPage from "./Components/ProductPage";

import Login from "./Components/User/Login";
import HeaderLeft from "./Components/HeaderLeft";
import SignUp from "./Components/User/SignUp";
import PricingPage from "./Components/PricingPage";
import Profile from "./Components/User/Profile";
import Footer from "./Components/Footer";
import TermsConditions from "./Components/TermsConditions";
import BillingPage from "./Components/RegisteredUser/BillingPage";
import SupportPage from "./Components/RegisteredUser/SupportPage";
import MonitoringPage from "./Components/RegisteredUser/MonitoringPage";
import CDNPage from "./Components/RegisteredUser/CDNPage";
import CreateMachine from "./Components/RegisteredUser/CreateMachine";
import WalletPage from "./Components/RegisteredUser/WalletPage";
import ChangePassword from "./Components/User/ChangePassword";
import MyMachinePage from "./Components/RegisteredUser/MyMachinePage";
import UtilisationsPage from "./Components/RegisteredUser/UtilisationsPage";
import BlogsMore from "./Components/Details/BlogsMore";
import { useAuth } from "./AuthContext";
import Notification from "./Components/RegisteredUser/Notification";
import PaymentData from "./Components/RegisteredUser/PaymentData";
import BillReport from "./Components/RegisteredUser/BillReport";
import InvoicePage from "./Components/User/InvoicePage";
import MachineStatus from "./Components/RegisteredUser/MachineStatus";
import EditMachine from "./Components/RegisteredUser/EditMachine";
// import ProductDetails from "./Components/ProductDetailsWindows";
import EmailVerification from "./Components/RegisteredUser/EmailVerification";
import ProductDetailsUbantu from "./Components/ProductDetailsUbantu";
import ProductDetailsWindows from "./Components/ProductDetailsWindows";
import ForgotPassword from "./Components/User/ForgotPassword";
import ResetPassword from "./Components/User/ResetPassword";
import { generateToken, messaging } from "./firebase";
import { createGlobalStyle } from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import AppToast from "./AppToast";
import instance, { decryptData } from "./Api";
import { DropdownProvider } from "./Components/Header";

// import getMAC from "getmac";

// import * as FingerprintJS from "fingerprintjs2";

const App = () => {
  const [hideFooter, setHideFooter] = useState(false);
  const [popup, setPopup] = useState(true);
  const [userIP, setUserIP] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  function isMobileDevice() {
    return window.matchMedia("(max-width: 1200px)").matches;
  }
  const { smuser } = useAuth();
  const FetchUser = () => {
    const storedUserData = localStorage.getItem("NEW_USER");
    // const storedSocial = localStorage.getItem("SocialLogin");
    // ocalStorage.setItem("SocialLogin", true);
    return storedUserData ? JSON.parse(storedUserData) : null;
  };

  // useEffect(() => {
  //   const client = new ClientJS();
  //   const fingerprint = client.getFingerprint();
  //   console.log(fingerprint); // Unique fingerprint
  // }, []);

  const sendTokenToServer = async () => {
    if (localStorage.getItem("NewfcmUPNET") !== null) {
      const storedUserFcmToken = localStorage
        .getItem("NewfcmUPNET")
        .replace(/"/g, "");
      if (storedUserFcmToken) {
        console.log(storedUserFcmToken, "OLD");
        await updateNotification(storedUserFcmToken);
      }
    } else {
      const token = await generateToken();
      if (token) {
        console.log(token, "NEW");
        await updateNotification(token);
      }
    }
  };

  const updateNotification = async (token) => {
    console.log(token, "updateNotification");
    try {
      await instance.post(
        `/saveUnauthUsersDeviceDetails?id=1&FCM_TOKEN=${token}&FINGERPRINT=${null}&DEVICE_LANGUAGE=${null}`
      );
      console.log("FCM token saved on the server successfully.");
    } catch (error) {
      console.error("Error saving FCM token:", error);
    }
  };

  useEffect(() => {
    // console.log(getMAC());
    //generateToken();
    sendTokenToServer();
    onMessage(messaging, (payload) => {
      //alert(`${payload.data.title}, ${payload.data.body}`);
      console.log(payload, "onBackgroundMessage1");

      let notificationTitle;
      let notificationOptions;

      // Check if notification data is directly available or within data
      if (payload.notification) {
        notificationTitle = payload.notification.title;
        notificationOptions = {
          body: payload.notification.body,
          icon: payload.notification.image,
          image: payload.data.image, // Display the image in the notification if supported
        };
      } else if (payload.data) {
        notificationTitle = payload.data.title;
        notificationOptions = {
          body: payload.data.body,
          icon: payload.data.image,
          image: payload.data.image, // Display the image in the notification if supported
        };
      }

      //  registration.showNotification(notificationTitle, notificationOptions);
      //toast(<Message notification={payload.notification} />);
      toast((t) => (
        <AppToast
          id={t.id}
          message={`${payload.data.title}\n ${payload.data.body}`}
        />
      ));
      // window.self.registration.showNotification(
      //   notificationTitle,
      //   notificationOptions
      // );
      //alert(payload.notification.title);

      console.log(payload);
    });
    // messaging.onMessage((payload) => {
    //   console.log("Message received. ", payload);
    //   // Customize notification handling here
    //   alert(payload.notification.title);
    // });

    async function fetchUserIP() {
      try {
        // Fetch user IP address
        const ip = await window.getUserIP();
        if (ip === "Unknown") {
          setUserIP(null); // Handle 'Unknown' IP if needed
        } else {
          setUserIP(ip);
        }
      } catch (error) {
        console.error("Error fetching user IP:", error);
        setUserIP(null); // Handle error fetching IP
      }
    }
    console.log(
      window.navigator.userAgent,
      window.navigator.language,
      window.getUserIP(),
      "window.navigator.userAgent"
    );
    // sendTokenToServer();
    // const unsubscribe = onMessage(messaging, (payload) => {
    //   console.log("Message received. ", payload);
    //   // Customize how you want to handle foreground messages here
    // });
    fetchUserIP();
    function handleResize() {
      setIsMobile(isMobileDevice());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      //unsubscribe();
    };
  }, []);

  // if (userIP === null) {
  //   // Return loading indicator or placeholder while fetching IP
  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         minHeight: "55rem",
  //         position: "relative",
  //         backgroundImage: isMobile
  //           ? `url(./main-bg.jpg)`
  //           : `url(./main-bg.jpg)`,
  //         backgroundSize: "cover",
  //         // backgroundPosition: "center",
  //         // backgroundColor: "#141414",
  //         backgroundRepeat: "round",
  //         backgroundBlendMode: "overlay",
  //       }}
  //     ></div>
  //   );
  // }
  const GlobalStyle = createGlobalStyle`
  body {
    background-image: url('/main-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: round;
    height: 100vh;
    margin: 0;
    width: 100%;
  }
`;

  return (
    <Router>
      <DropdownProvider>
        <UserProvider>
          <GlobalStyle />
          <div
            style={{
              width: "100%",
              minHeight: "75rem",
              position: "relative",
              backgroundImage: isMobile
                ? `url(./main-bg.jpg)`
                : `url(./main-bg.jpg)`,
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "round",
              backgroundBlendMode: "overlay",
            }}
            onClick={() => setPopup(!popup)}
          >
            {newUser === null ? (
              <Header user={newUser} popup={popup} />
            ) : (
              <Header popup={popup} />
            )}
            {userIP && (
              <div>
                <Routes>
                  <Route
                    path="/"
                    element={<Home ip={userIP} user={newUser} />}
                  />
                  <Route
                    path="/solutions"
                    element={<SolutionsPage ip={userIP} />}
                  />
                  <Route
                    path="/blog"
                    element={<Blog ip={userIP} isMobile={isMobile} />}
                  />
                  <Route path="/blogmore" element={<BlogsMore ip={userIP} />} />
                  <Route
                    path="/product"
                    element={<ProductPage isMobile={isMobile} />}
                  />
                  <Route
                    path="/product-details-ubantu"
                    element={
                      <ProductDetailsUbantu isMobile={isMobile} ip={userIP} />
                    }
                  />
                  <Route
                    path="/product-details-windows"
                    element={<ProductDetailsWindows isMobile={isMobile} />}
                  />
                  <Route
                    path="/pricing"
                    element={<PricingPage isMobile={isMobile} />}
                  />
                  <Route
                    path="/termsConditions"
                    element={<TermsConditions isMobile={isMobile} />}
                  />
                  <Route
                    path="/login"
                    element={<Login isMobile={isMobile} ip={userIP} />}
                  />
                  <Route
                    path="/signUp"
                    element={<SignUp isMobile={isMobile} ip={userIP} />}
                  />
                  {/* /vm/create */}
                  <Route
                    path="/vm/create"
                    element={
                      smuser !== null ? (
                        <CreateMachine isMobile={isMobile} />
                      ) : (
                        <SignUp isMobile={isMobile} />
                      )
                    }
                  />
                  <Route
                    path="/bill"
                    element={
                      smuser !== null ? (
                        <BillingPage isMobile={isMobile} />
                      ) : (
                        <SignUp isMobile={isMobile} />
                      )
                    }
                  />
                  <Route
                    path="/forgotpassword"
                    element={<ForgotPassword isMobile={isMobile} />}
                  />

                  <Route
                    path="/reset_password/*"
                    element={<ResetPassword isMobile={isMobile} />}
                  />

                  {smuser !== null ? (
                    <>
                      <Route
                        path="/email/verification"
                        element={<EmailVerification isMobile={isMobile} />}
                      />
                      <Route
                        path="/create-ticket"
                        element={<SupportPage isMobile={isMobile} />}
                      />
                      <Route
                        path="/vm/monitor"
                        element={<MonitoringPage isMobile={isMobile} />}
                      />
                      <Route
                        path="/wallet"
                        element={<WalletPage isMobile={isMobile} />}
                      />
                      <Route
                        path="/vm-machine"
                        element={<MyMachinePage isMobile={isMobile} />}
                      />
                      <Route
                        path="/vm/moniter/utilize"
                        element={<UtilisationsPage isMobile={isMobile} />}
                      />
                      //
                      <Route
                        path="/vm/cdn"
                        element={<CDNPage isMobile={isMobile} />}
                      />
                      <Route
                        path="/change-password"
                        element={<ChangePassword isMobile={isMobile} />}
                      />
                      <Route
                        path="/edit-profile"
                        element={<Profile isMobile={isMobile} />}
                      />{" "}
                      <Route
                        path="/notification"
                        element={<Notification isMobile={isMobile} />}
                      />
                      <Route
                        path="/paymentdata"
                        element={<PaymentData isMobile={isMobile} />}
                      />
                      <Route
                        path="/billreport"
                        element={<BillReport isMobile={isMobile} />}
                      />
                      <Route
                        path="/invoice"
                        element={<InvoicePage isMobile={isMobile} />}
                      />
                      <Route
                        path="/vm/status"
                        element={<MachineStatus isMobile={isMobile} />}
                      />
                      <Route
                        path="/edit-vm"
                        element={<EditMachine isMobile={isMobile} />}
                      />
                      <Route
                        path="/succes-payment"
                        element={<EditMachine isMobile={isMobile} />}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Routes>
              </div>
            )}

            <Footer ip={userIP} />
            {/* {isMobile && <Footer />} */}
          </div>
          <HeaderLeft />
        </UserProvider>
      </DropdownProvider>
    </Router>
  );
};

export default App;
