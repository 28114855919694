import React, { useRef, useState } from "react";
import { Container, Button, Carousel, Spinner, Row } from "react-bootstrap";

import "./BlogsMore.css";

const BlogsMore = (props) => {
  const { isMobile } = props;

  const banner = [
    "slider-image-01.png",
    "slider-image-02.png",
    "slider-image-03.png",
    "slider-image-04.png",
    "slider-image-05.png",
  ];
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div
      style={{
        width: "100%",
        // minHeight: "100%",
        position: "relative",
        backgroundImage: isMobile ? `url(./main-bg.jpg)` : `url(./main-bg.jpg)`,
        // backgroundSize: "cover",
        backgroundPosition: "center",
        // backgroundColor: "#141414",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
        // backgroundColor: "white",
      }}
    >
      {isMobile ? (
        <>
          <div>
            <Row style={{ paddingTop: "5rem", width: "100%" }}>
              <div className="col-md-2"></div>
              <div
                className="col-md-7"
                style={{
                  backgroundColor: "white",
                  marginLeft: "25px",
                  paddingRight: "35px",
                }}
              >
                <div class="blogss-posts">
                  <div class="blogss-posts-inner">
                    <div class="blogss-post scrollable-content">
                      <div class="blogss-post-inner">
                        <div
                          class="blogs-image-outer"
                          style={{ paddingLeft: "10px" }}
                        >
                          <img
                            src="https://www.smartcloudvm.com/public/images/frontend/blog/img4.png"
                            class="blogs-image"
                          />
                        </div>
                        <div class="blogs-body">
                          <div class="blogs-author">Potenti Mauriss</div>
                          <div class="blogs-date">
                            <img
                              src="https://www.smartcloudvm.com/public/images/frontend/blog/pen.svg"
                              class="blogs-pen-icon"
                            />
                            16th September, 2015
                          </div>
                          <div
                            class="blogs-title"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          >
                            Overall best CPU based virtual machines, deploy in
                            less than 30 seconds.
                          </div>
                          <div class="blogs-social">
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/instagram.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/facebook.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/twitter.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/youtube.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="blogs-other-content ">
                        <div
                          class="blogs-para"
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </div>
                        <div
                          class="blogs-para-title"
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                        <div class="blogs-para">
                          <div class="para-image1">
                            <img src="https://www.smartcloudvm.com/public/images/frontend/mobile-images/blog-details/img-02.jpg" />
                          </div>
                          <div
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          >
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                        </div>
                        <div
                          class="blogs-para-title"
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                        <div
                          class="blogs-para2"
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </div>
                        <div
                          class="blogs-para-title"
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                        <div class="blogs-para">
                          <div class="para-image2">
                            <mg src="https://www.smartcloudvm.com/public/images/frontend/mobile-images/blog-details/img-02.jpg" />
                          </div>
                          <div
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          >
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3" style={{ width: "100%" }}>
                <div class="latest-blogss">
                  <div class="latest-blogss-inner">
                    <div class="blog-categories">
                      <div class="section-top">
                        <div class="blogss-heading">
                          Categories<span></span>
                        </div>
                      </div>
                      <ul class="categories-list">
                        <li>
                          <a href="#" class="blog-category">
                            Lifestyle (10)
                          </a>
                        </li>
                        <li>
                          <a href="#" class="blog-category">
                            Travel (24)
                          </a>
                        </li>
                        <li>
                          <a href="#" class="blog-category">
                            Music (13)
                          </a>
                        </li>
                        <li>
                          <a href="#" class="blog-category">
                            Featured (23)
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="latest-posts-cont">
                      <div class="section-top">
                        <div class="blogss-heading">
                          Latest Posts<span></span>
                        </div>
                      </div>
                      <div class="latest-posts">
                        <div class="latest-post">
                          <div class="latest-post-inner">
                            <div class="blog-image-outer"></div>
                            <div class="blog-body">
                              <div class="blog-post-row">
                                <div class="blog-author">Potenti Mauris</div>
                                <div class="blog-date">
                                  16th September, 2015
                                </div>
                              </div>
                              <div class="blogs-title">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds
                              </div>
                              <div class="blog-content">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds. Overall best CPU based
                                virtual machines, deploy in less than 30
                                seconds.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="latest-post">
                          <div class="latest-post-inner">
                            <div class="blog-image-outer"></div>
                            <div class="blog-body">
                              <div class="blog-post-row">
                                <div class="blog-author">Potenti Mauris</div>
                                <div class="blog-date">
                                  16th September, 2015
                                </div>
                              </div>
                              <div class="blogs-title">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds
                              </div>
                              <div class="blog-content">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds. Overall best CPU based
                                virtual machines, deploy in less than 30
                                seconds.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="latest-post">
                          <div class="latest-post-inner">
                            <div class="blog-image-outer"></div>
                            <div class="blog-body">
                              <div class="blog-post-row">
                                <div class="blog-author">Potenti Mauris</div>
                                <div class="blog-date">
                                  16th September, 2015
                                </div>
                              </div>
                              <div class="blogs-title">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds
                              </div>
                              <div class="blog-content">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds. Overall best CPU based
                                virtual machines, deploy in less than 30
                                seconds.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Row>
          </div>
        </>
      ) : (
        <>
          <div style={{ height: "100vh" }}>
            <Row style={{ paddingTop: "8rem" }}>
              <div className="col-md-2"></div>
              <div className="col-md-7">
                <div class="blogss-posts">
                  <div class="blogss-posts-inner">
                    <div class="blogss-post scrollable-content">
                      <div class="blogss-post-inner">
                        <div class="blogs-image-outer">
                          <img
                            src="https://www.smartcloudvm.com/public/images/frontend/blog/img4.png"
                            class="blogs-image"
                          />
                        </div>
                        <div class="blogs-body">
                          <div class="blogs-author">Potenti Mauris</div>
                          <div class="blogs-date">
                            <img
                              src="https://www.smartcloudvm.com/public/images/frontend/blog/pen.svg"
                              class="blogs-pen-icon"
                            />
                            16th September, 2015
                          </div>
                          <div class="blogs-title">
                            Overall best CPU based virtual machines, deploy in
                            less than 30 seconds.
                          </div>
                          <div class="blogs-social">
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/instagram.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/facebook.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/twitter.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                            <a href="#">
                              <img
                                src="https://www.smartcloudvm.com/public/images/frontend/blog/youtube.svg"
                                class="blogs-social-icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="blogs-other-content ">
                        <div class="blogs-para">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </div>
                        <div class="blogs-para-title">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                        <div class="blogs-para">
                          <div class="para-image1">
                            <img src="https://www.smartcloudvm.com/public/images/frontend/mobile-images/blog-details/img-02.jpg" />
                          </div>
                          <div>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                        </div>
                        <div class="blogs-para-title">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                        <div class="blogs-para2">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </div>
                        <div class="blogs-para-title">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </div>
                        <div class="blogs-para">
                          <div class="para-image2">
                            <mg src="https://www.smartcloudvm.com/public/images/frontend/mobile-images/blog-details/img-02.jpg" />
                          </div>
                          <div>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3" style={{ width: "100%" }}>
                <div class="latest-blogss">
                  <div class="latest-blogss-inner">
                    <div class="blog-categories">
                      <div class="section-top">
                        <div class="blogss-heading">
                          Categories<span></span>
                        </div>
                      </div>
                      <ul class="categories-list">
                        <li>
                          <a href="#" class="blog-category">
                            Lifestyle (10)
                          </a>
                        </li>
                        <li>
                          <a href="#" class="blog-category">
                            Travel (24)
                          </a>
                        </li>
                        <li>
                          <a href="#" class="blog-category">
                            Music (13)
                          </a>
                        </li>
                        <li>
                          <a href="#" class="blog-category">
                            Featured (23)
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="latest-posts-cont">
                      <div class="section-top">
                        <div class="blogss-heading">
                          Latest Posts<span></span>
                        </div>
                      </div>
                      <div class="latest-posts">
                        <div class="latest-post">
                          <div class="latest-post-inner">
                            <div class="blog-image-outer"></div>
                            <div class="blog-body">
                              <div class="blog-post-row">
                                <div class="blog-author">Potenti Mauris</div>
                                <div class="blog-date">
                                  16th September, 2015
                                </div>
                              </div>
                              <div class="blogs-title">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds
                              </div>
                              <div class="blog-content">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds. Overall best CPU based
                                virtual machines, deploy in less than 30
                                seconds.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="latest-post">
                          <div class="latest-post-inner">
                            <div class="blog-image-outer"></div>
                            <div class="blog-body">
                              <div class="blog-post-row">
                                <div class="blog-author">Potenti Mauris</div>
                                <div class="blog-date">
                                  16th September, 2015
                                </div>
                              </div>
                              <div class="blogs-title">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds
                              </div>
                              <div class="blog-content">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds. Overall best CPU based
                                virtual machines, deploy in less than 30
                                seconds.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="latest-post">
                          <div class="latest-post-inner">
                            <div class="blog-image-outer"></div>
                            <div class="blog-body">
                              <div class="blog-post-row">
                                <div class="blog-author">Potenti Mauris</div>
                                <div class="blog-date">
                                  16th September, 2015
                                </div>
                              </div>
                              <div class="blogs-title">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds
                              </div>
                              <div class="blog-content">
                                Overall best CPU based virtual machines, deploy
                                in less than 30 seconds. Overall best CPU based
                                virtual machines, deploy in less than 30
                                seconds.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default BlogsMore;
