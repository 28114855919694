import React, { useRef, useState } from "react";
import { Container, Button, Carousel, Spinner, Row } from "react-bootstrap";

import "./Blog.css";

const Blog = (props) => {
  const { isMobile } = props;

  const banner = [
    "slider-image-01.png",
    "slider-image-02.png",
    "slider-image-03.png",
    "slider-image-04.png",
    "slider-image-05.png",
  ];
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      {isMobile ? (
        <>
          <div className="heading-dotted" style={{ width: "30%" }}>
            Category
          </div>
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              width: "100%",
              overflow: "scroll",
              paddingBottom: "0.5rem",
              height: "130px",
              gap: "15px",
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
              <div
                style={{
                  paddingLeft: "20px",
                  // width: "48px",
                  // height: "60px",
                }}
              >
                <div
                  style={{
                    top: "25px",
                    left: "60px",
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#e97730",
                    fontSize: "10px",
                    color: "white",
                    paddingLeft: "2px",
                  }}
                >
                  14
                </div>
                <img
                  src="/images/frontend_old/mobile-images/blog/lifestyle-icon.svg"
                  style={{
                    width: "80px",
                    height: "80px",
                    border: "3px solid #e97730",
                    borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontSize: "17px",
                  }}
                >
                  Lifestyle
                </div>
              </div>
            ))}
          </div>
          <div className="blogs-posts-inner-mob">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
              <div className="blog-post-mob">
                <div
                  className="blogs-post-inner-mob"
                  style={{ width: "16rem" }}
                >
                  <div className="blog-image-outer">
                    <img
                      src="/images/frontend/blog/img.png"
                      className="blog-image"
                    />
                  </div>
                  <div className="blog-body">
                    <div className="blog-post-row row-top">
                      <div className="blog-author">Potenti Mauris</div>
                    </div>
                    <div className="blog-title" style={{ fontSize: "17px" }}>
                      Overall best CPU based virtual machines, deploy in less
                      than 30 seconds.
                    </div>

                    <div className="blog-date" style={{ fontSize: "12px" }}>
                      <img
                        src="/images/frontend/blog/pen.svg"
                        className="blog-pen-icon"
                      />
                      16th September, 2015
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="blogs-posts-inner-mob-vert">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
              <div className="blog-post-mob" style={{ marginTop: "10px" }}>
                <div
                  className="blogs-post-inner-mob"
                  style={{ display: "flex" }}
                >
                  <div
                    className="blog-image-outer"
                    style={{ marginBottom: "1px" }}
                  >
                    <img
                      src="/images/frontend/blog/img.png"
                      className="blog-image"
                      style={{ marginTop: "18px" }}
                    />
                  </div>
                  <div className="blog-body">
                    <div className="blog-post-row row-top">
                      <div className="blog-author">Potenti Mauris</div>
                    </div>
                    <div className="blog-title" style={{ fontSize: "17px" }}>
                      Overall best CPU based virtual machines,
                    </div>

                    <div className="blog-date" style={{ fontSize: "12px" }}>
                      <img
                        src="/images/frontend/blog/pen.svg"
                        className="blog-pen-icon"
                      />
                      16th September, 2015
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div style={{ height: "100vh" }}>
          <Row style={{ paddingTop: "8rem" }}>
            <div className="col-md-2"></div>
            <div className="col-md-7">
              <div className="blogs-posts-inner">
                <div className="blog-post">
                  <div className="blogs-post-inner">
                    <div className="blog-image-outer">
                      <img
                        src="/images/frontend/blog/img.png"
                        className="blog-image"
                      />
                    </div>
                    <div className="blog-body">
                      <div className="blog-post-row row-top">
                        <div className="blog-author">Potenti Mauris</div>
                        <div className="blog-date">
                          <img
                            src="/images/frontend/blog/pen.svg"
                            className="blog-pen-icon"
                          />
                          16th September, 2015
                        </div>
                      </div>
                      <div className="blog-title">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds.
                      </div>
                      <div className="blog-content">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds. Overall best CPU based virtual
                        machines, deploy in less than 30 seconds.
                      </div>
                      <div className="blog-post-row row-bottom">
                        <a href="/blogmore">
                          <div className="blog-more">Read More...</div>
                        </a>
                        <div className="blog-social">
                          <a>
                            <img
                              src="/images/frontend/blog/instagram.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/facebook.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/twitter.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/youtube.svg"
                              className="blog-social-icon"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-post">
                  <div className="blogs-post-inner">
                    <div className="blog-image-outer">
                      <img
                        src="/images/frontend/blog/img.png"
                        className="blog-image"
                      />
                    </div>
                    <div className="blog-body">
                      <div className="blog-post-row row-top">
                        <div className="blog-author">Potenti Mauris</div>
                        <div className="blog-date">
                          <img
                            src="/images/frontend/blog/pen.svg"
                            className="blog-pen-icon"
                          />
                          16th September, 2015
                        </div>
                      </div>
                      <div className="blog-title">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds.
                      </div>
                      <div className="blog-content">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds. Overall best CPU based virtual
                        machines, deploy in less than 30 seconds.
                      </div>
                      <div className="blog-post-row row-bottom">
                        <a href="/blogmore">
                          <div className="blog-more">Read More...</div>
                        </a>
                        <div className="blog-social">
                          <a>
                            <img
                              src="/images/frontend/blog/instagram.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/facebook.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/twitter.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/youtube.svg"
                              className="blog-social-icon"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-post">
                  <div className="blogs-post-inner">
                    <div className="blog-image-outer">
                      <img
                        src="/images/frontend/blog/img.png"
                        className="blog-image"
                      />
                    </div>
                    <div className="blog-body">
                      <div className="blog-post-row row-top">
                        <div className="blog-author">Potenti Mauris</div>
                        <div className="blog-date">
                          <img
                            src="/images/frontend/blog/pen.svg"
                            className="blog-pen-icon"
                          />
                          16th September, 2015
                        </div>
                      </div>
                      <div className="blog-title">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds.
                      </div>
                      <div className="blog-content">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds. Overall best CPU based virtual
                        machines, deploy in less than 30 seconds.
                      </div>
                      <div className="blog-post-row row-bottom">
                        <a href="/blogmore">
                          <div className="blog-more">Read More...</div>
                        </a>
                        <div className="blog-social">
                          <a>
                            <img
                              src="/images/frontend/blog/instagram.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/facebook.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/twitter.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/youtube.svg"
                              className="blog-social-icon"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-post">
                  <div className="blogs-post-inner">
                    <div className="blog-image-outer">
                      <img
                        src="/images/frontend/blog/img.png"
                        className="blog-image"
                      />
                    </div>
                    <div className="blog-body">
                      <div className="blog-post-row row-top">
                        <div className="blog-author">Potenti Mauris</div>
                        <div className="blog-date">
                          <img
                            src="/images/frontend/blog/pen.svg"
                            className="blog-pen-icon"
                          />
                          16th September, 2015
                        </div>
                      </div>
                      <div className="blog-title">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds.
                      </div>
                      <div className="blog-content">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds. Overall best CPU based virtual
                        machines, deploy in less than 30 seconds.
                      </div>
                      <div className="blog-post-row row-bottom">
                        <a href="/blogmore">
                          <div className="blog-more">Read More...</div>
                        </a>
                        <div className="blog-social">
                          <a>
                            <img
                              src="/images/frontend/blog/instagram.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/facebook.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/twitter.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/youtube.svg"
                              className="blog-social-icon"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-post">
                  <div className="blogs-post-inner">
                    <div className="blog-image-outer">
                      <img
                        src="/images/frontend/blog/img.png"
                        className="blog-image"
                      />
                    </div>
                    <div className="blog-body">
                      <div className="blog-post-row row-top">
                        <div className="blog-author">Potenti Mauris</div>
                        <div className="blog-date">
                          <img
                            src="/images/frontend/blog/pen.svg"
                            className="blog-pen-icon"
                          />
                          16th September, 2015
                        </div>
                      </div>
                      <div className="blog-title">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds.
                      </div>
                      <div className="blog-content">
                        Overall best CPU based virtual machines, deploy in less
                        than 30 seconds. Overall best CPU based virtual
                        machines, deploy in less than 30 seconds.
                      </div>
                      <div className="blog-post-row row-bottom">
                        <a href="/blogmore">
                          <div className="blog-more">Read More...</div>
                        </a>
                        <div className="blog-social">
                          <a>
                            <img
                              src="/images/frontend/blog/instagram.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/facebook.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/twitter.svg"
                              className="blog-social-icon"
                            />
                          </a>
                          <a>
                            <img
                              src="/images/frontend/blog/youtube.svg"
                              className="blog-social-icon"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3" style={{ width: "24%" }}>
              <div class="latest-blogs">
                <div class="latest-blogs-inner">
                  <div class="blog-categories">
                    <div class="section-top">
                      <div class="blogs-heading">
                        Categories<span></span>
                      </div>
                    </div>
                    <ul class="categories-list">
                      <li>
                        <a class="blog-category">Lifestyle (10)</a>
                      </li>
                      <li>
                        <a class="blog-category">Travel (24)</a>
                      </li>
                      <li>
                        <a class="blog-category">Music (13)</a>
                      </li>
                      <li>
                        <a class="blog-category">Featured (23)</a>
                      </li>
                    </ul>
                  </div>
                  <div class="latest-posts-cont">
                    <div class="section-top">
                      <div class="blogs-heading">
                        Latest Posts<span></span>
                      </div>
                      <a class="all-blogs-link">See all</a>
                    </div>
                    <div class="latest-posts">
                      <div class="latest-post">
                        <div class="latest-post-inner">
                          <div class="blog-image-outer">
                            <img
                              src="/images/frontend/blog/img4.png"
                              class="blog-image"
                            />
                          </div>
                          <div class="blog-body">
                            <div class="blog-post-row">
                              <div class="blog-author">Potenti Mauris</div>
                              <div class="blog-date">
                                <img
                                  src="/images/frontend/blog/pen.svg"
                                  class="blog-pen-icon"
                                />
                                16th September, 2015
                              </div>
                            </div>
                            <div class="blog-title">
                              Overall best CPU based virtual machines, deploy in
                              less than 30 seconds
                            </div>
                            <div class="blog-content">
                              Overall best CPU based virtual machines, deploy in
                              less than 30 seconds. Overall best CPU based
                              virtual machines, deploy in less than 30 seconds.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="latest-post">
                        <div class="latest-post-inner">
                          <div class="blog-image-outer">
                            <img
                              src="/images/frontend/blog/img.png"
                              class="blog-image"
                            />
                          </div>
                          <div class="blog-body">
                            <div class="blog-post-row">
                              <div class="blog-author">Potenti Mauris</div>
                              <div class="blog-date">
                                <img
                                  src="/images/frontend/blog/pen.svg"
                                  class="blog-pen-icon"
                                />
                                16th September, 2015
                              </div>
                            </div>
                            <div class="blog-title">
                              Overall best CPU based virtual machines, deploy in
                              less than 30 seconds
                            </div>
                            <div class="blog-content">
                              Overall best CPU based virtual machines, deploy in
                              less than 30 seconds. Overall best CPU based
                              virtual machines, deploy in less than 30 seconds.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="latest-post">
                        <div class="latest-post-inner">
                          <div class="blog-image-outer">
                            <img
                              src="/images/frontend/blog/img2.png"
                              class="blog-image"
                            />
                          </div>
                          <div class="blog-body">
                            <div class="blog-post-row">
                              <div class="blog-author">Potenti Mauris</div>
                              <div class="blog-date">
                                <img
                                  src="/images/frontend/blog/pen.svg"
                                  class="blog-pen-icon"
                                />
                                16th September, 2015
                              </div>
                            </div>
                            <div class="blog-title">
                              Overall best CPU based virtual machines, deploy in
                              less than 30 seconds
                            </div>
                            <div class="blog-content">
                              Overall best CPU based virtual machines, deploy in
                              less than 30 seconds. Overall best CPU based
                              virtual machines, deploy in less than 30 seconds.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Blog;
