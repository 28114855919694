import React, { useEffect, useState } from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import "./Notification.css";
import { useAuth } from "../../AuthContext";
import instance, {
  apiDecrypteRequest,
  apiEncryptRequest,
  currencyReturn,
  decryptData,
} from "../../Api";
import { FaCalendar, FaRupeeSign } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";

// import RangeSlider from "./common/RangeSlider";

const Notification = () => {
  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }
  const navigate = useNavigate();
  const { smuser, appCurrency } = useAuth();

  const [allNotificationCloud, setAllNotificationCloud] = useState([]);
  const [allNotificationNative, setAllNotificationNative] = useState([]);

  const [allNotification, setAllNotification] = useState([]);
  const [adminNoti, setadminNoti] = useState([]);
  const [vmNoti, setVmNoti] = useState([]);
  const [billNoti, setBillNoti] = useState([]);
  const [paymentNoti, setPaymentNoti] = useState([]);
  const [systemNoti, setSystemNoti] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allnotiArchive, setAllnotiArchive] = useState([]);

  const [isLatest, setIsLatest] = useState(true);

  const handleViewInvoice = (data) => {
    // console.log(data);
    const item = {
      id: data.paymnet_id,
      orderid: data.order_id,
    };

    navigate("/invoice", {
      state: { billData: item },
    });
  };

  const ArchiveCall = async (notifiID) => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
      notification_id: notifiID,
    };
    //console.log(payload, "ONNNN");
    try {
      // First API call to encrypt the request
      const encryptedResponse = await apiEncryptRequest(payload);
      //console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const distroyResponse = await instance.post(
        "/notificationarchive",
        encryptedResponse
      );
      //console.log(distroyResponse.data, "==notificationarchive");
      GetNotification();
      // Third API call to decrypt the login response
      const Response = await decryptData(distroyResponse.data);
      //console.log(Response, "====notificationarchive");
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };

  const GetNotification = async () => {
    setLoading(true);
    const payload = {
      user_id: smuser.id,
    };
    try {
      // First API call to encrypt the request
      // const encryptedResponse = await apiEncryptRequest(payload);
      // ////console.log(encryptedResponse, "=encryptedResponse");

      // Second API call to login with encrypted response
      const notificationResponse = await instance.post(
        "/notification",
        payload
      );
      ////console.log(notificationResponse.data, "====notificationResponse");
      const allnotificationarchive =
        notificationResponse.data.allnotificationarchive;
      ////console.log(allnotificationarchive, "====allnotificationarchive");
      setAllnotiArchive(allnotificationarchive);

      const allnotificationnative =
        notificationResponse.data.allnotificationnative;
      setAllNotificationNative(allnotificationnative);

      const allnotificationcloud =
        notificationResponse.data.allnotificationcloud;
      setAllNotificationCloud(allnotificationcloud);
      // smuser.platform_status === 0 means Native and 1 means Cloud
      if (smuser.platform_status === 0) {
        const vmDataAdmin = allnotificationnative.filter((item) =>
          [20].includes(item.use_history_type)
        );
        const vmData = allnotificationnative.filter((item) =>
          [8, 7, 6].includes(item.use_history_type)
        );
        const billData = allnotificationnative.filter((item) =>
          [11, 12].includes(item.use_history_type)
        );

        const payData = allnotificationnative.filter((item) =>
          [9, 10].includes(item.use_history_type)
        );
        ////console.log(payData, "-------payData");
        setBillNoti(billData.reverse());
        setadminNoti(vmDataAdmin.reverse());
        setVmNoti(vmData.reverse());
        setPaymentNoti(payData.reverse());
        setAllNotificationNative(allnotificationnative.reverse());
        setAllNotification(allnotificationnative.reverse());
      } else {
        const vmDataAdmin = allnotificationcloud.filter((item) =>
          [20].includes(item.use_history_type)
        );
        const vmData = allnotificationcloud.filter((item) =>
          [8, 7, 6].includes(item.use_history_type)
        );
        const billData = allnotificationcloud.filter((item) =>
          [11, 12].includes(item.use_history_type)
        );

        const payData = allnotificationcloud.filter((item) =>
          [9, 10].includes(item.use_history_type)
        );
        ////console.log(payData.reverse(), "-------payData");
        setBillNoti(billData.reverse());
        setadminNoti(vmDataAdmin.reverse());
        setVmNoti(vmData.reverse());
        setPaymentNoti(payData.reverse());
        setAllNotificationCloud(allnotificationcloud.reverse());
        setAllNotification(allnotificationcloud.reverse());
      }

      ////console.log(allnotificationcloud);
    } catch (error) {
      console.error("Error during the login process:", error);
    }
    setLoading(false);
  };
  //
  const getVoice = async () => {
    const payload = {
      user_id: smuser.id,
      id: 7,
    };
    try {
      const eeeeeeee = await instance.post("/invoiceview", payload);
      ////console.log(eeeeeeee.data, "====eeeeeeee");
    } catch (error) {
      console.error("Error during the login process:", error);
    }
  };
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [activeButton, setActiveButton] = useState(6);

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const innerButtons = [
    "Admin Notification (0)",
    "VM Notification (8)",
    "Billing Notification (6)",
    "Payment Notification (16)",
    //"System Profile Notification (6)",
    "All Notification (36)",
  ];

  const changeCurrency = (value) => {
    // Add your currency change logic here
    //console.log("Currency changed to:", value);
  };
  useEffect(() => {
    GetNotification();
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const featureListStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    padding: "20px",
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "65rem",
        position: "relative",
        backgroundImage: isMobile ? `url(./main-bg.jpg)` : `url(./main-bg.jpg)`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundColor: "#141414",
        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
          {/* <Spinner animation="border" /> */}
        </div>
      )}
      {isMobile ? (
        <div className="" style={{ height: "50rem" }}>
          <div className="heading-dotted-machine">
            Notification <span></span>
          </div>

          <div className="billing-table-cont">
            <div
              className="billing-search"
              style={{
                position: "relative",
                left: "20px",
                width: "60%",
                height: "15rem",
              }}
            >
              <div className="search-form">
                <div className="input-container-notif">
                  {/* <FaCalendar style={{ color: "white" }} /> */}
                  <input
                    //   value={email}
                    type="date"
                    name="from"
                    className="input-signup"
                    placeholder="From"
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-container-notif">
                  {/* <FaCalendar style={{ color: "white" }} /> */}
                  <input
                    //   value={email}
                    type="date"
                    name="to"
                    className="input-signup"
                    placeholder="To"
                    //   style={{color:'white'}}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <input type="hidden" id="getval" value="All" />
                <div className="button-fields">
                  <div
                    className="button-cont"
                    style={{
                      marginTop: "-11px",
                    }}
                  >
                    <input
                      type="submit"
                      name="submit"
                      value="Submit"
                      className="input-submit new-btn"
                      // onClick={submitData}
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    top: "3rem",
                    width: "120px",
                    height: "60px",
                    backgroundColor: "transparent",
                    left: "130%",
                  }}
                >
                  <div
                    onClick={() => setIsLatest(false)}
                    style={{
                      borderRadius: "12px",
                      borderStartStartRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      position: "absolute",
                      zIndex: "1",
                      top: "0rem",
                      width: "60px",
                      height: "60px",
                      left: "60px",
                      backgroundColor: isLatest ? "#f1ddc8" : "#e97730",
                      // left: "75rem",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "12px",
                        marginTop: "20px",
                        color: isLatest ? "#e97730" : "white",
                      }}
                    >
                      Archive
                    </p>
                  </div>
                  <div
                    onClick={() => setIsLatest(true)}
                    style={{
                      borderRadius: "12px",
                      borderStartEndRadius: "0px",
                      borderBottomRightRadius: "0px",
                      position: "absolute",
                      zIndex: "1",
                      top: "0rem",
                      width: "60px",
                      height: "60px",
                      backgroundColor: isLatest ? "#e97730" : "#f1ddc8",
                      // left: "75rem",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "12px",
                        marginTop: "20px",
                        color: isLatest ? "white" : "#e97730",
                      }}
                    >
                      Latest
                    </p>
                  </div>
                </div>
              </div>

              <img
                src="/images/admin/09-Billing-Table/blue-bg.svg"
                className="bg-imagee mobile"
                alt="Background"
              />
            </div>
            <div className="tab-buttons-cont"></div>
            <div className="buttons-container" style={{ padding: "15px" }}>
              {/* {innerButtons.map((title, idx) => ( */}
              <Button
                className="list-btns-mobile"
                style={{
                  background: `${activeButton === 1 ? "#035189" : "#f47c20"}`,
                }}
                onClick={() => {
                  setActiveButton(1);
                  setAllNotification(adminNoti);
                }}
              >
                Admin Notification ({adminNoti !== null ? adminNoti.length : 0})
              </Button>
              <Button
                className="list-btns-mobile"
                style={{
                  background: `${activeButton === 2 ? "#035189" : "#f47c20"}`,
                }}
                onClick={() => {
                  setActiveButton(2);
                  setAllNotification(vmNoti);
                }}
              >
                VM Notification ({vmNoti !== null ? vmNoti.length : 0})
                {/* VM Notification {"("} {vmNoti !== null ? "": 0{")"}} */}
              </Button>
              <Button
                className="list-btns-mobile"
                style={{
                  background: `${activeButton === 3 ? "#035189" : "#f47c20"}`,
                }}
                onClick={() => {
                  setActiveButton(3);
                  setAllNotification(billNoti);
                }}
              >
                Billing Notification ({billNoti !== null ? billNoti.length : 0})
              </Button>

              <Button
                className="list-btns-mobile"
                style={{
                  background: `${activeButton === 4 ? "#035189" : "#f47c20"}`,
                }}
                onClick={() => {
                  setActiveButton(4);
                  setAllNotification(paymentNoti);
                }}
              >
                Payment Notification (
                {paymentNoti !== null ? paymentNoti.length : 0})
              </Button>

              {/* <Button
                className="list-btns-mobile"
                style={{
                  background: `${activeButton === 5 ? "#035189" : "#f47c20"}`,
                }}
                onClick={() => setActiveButton(5)}
              >
                System Profile Noti. {"("}0{")"}
              </Button> */}

              <Button
                className="list-btns-mobile"
                style={{
                  background: `${activeButton === 6 ? "#035189" : "#f47c20"}`,
                }}
                onClick={() => {
                  setActiveButton(6);
                  setAllNotification(
                    smuser.platform_status == 0
                      ? allNotificationNative.reverse()
                      : allNotificationCloud.reverse()
                  );
                }}
              >
                All Notification (
                {smuser.platform_status == 0 ? allNotificationNative.length : 0}
                )
              </Button>
              {/* ))} */}
            </div>
            {isLatest ? (
              <>
                <div className="notification-cont">
                  {allNotification &&
                    allNotification.map((item, idx) => (
                      <div className="table-row-noti">
                        <div className="bar"></div>
                        <div className="message">
                          {/* <div className="title">{item.title}</div> */}
                          <div className="title">{item.title}</div>
                          {/* UserIP */}
                          {activeButton === 1 &&
                            (item.remarks !== null ? (
                              <div class="desc">
                                Description : {item.remarks}
                              </div>
                            ) : null)}{" "}
                          {activeButton !== 1 && item.UserIP !== null ? (
                            <div class="desc">
                              USER LOGIN IP : {item.UserIP}
                            </div>
                          ) : null}
                          {item.machineID !== null ? (
                            <div className="desc">
                              Machine ID : {item.machineID}
                            </div>
                          ) : null}
                          {item.order_id !== null ? (
                            <div
                              style={{ maxHeight: "none" }}
                              className="desc"
                              onClick={() => handleViewInvoice(item)}
                            >
                              Payment ID :{" "}
                              <button
                                style={{
                                  color: "rgb(3, 81, 137)",
                                  border: "none",
                                  backgroundColor: "transparent",
                                  fontWeight: "600",
                                }}
                              >
                                {item.order_id}
                              </button>
                            </div>
                          ) : null}
                          {item.price !== null ? (
                            <div className="desc">
                              Amount:
                              {appCurrency &&
                                smuser &&
                                currencyReturn({
                                  price: item.price,
                                  symbol: smuser.prefer_currency,
                                  rates: appCurrency,
                                })}
                            </div>
                          ) : null}
                          {/* </div> */}
                        </div>
                        <div className="datetime">{item.created_at}</div>
                        <div className="archive-noti">
                          <a>
                            <img
                              src="/images/admin/17-Notification/archive.png"
                              title="Move to Archive"
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div className="notification-cont">
                  {allnotiArchive &&
                    allnotiArchive.map((item, idx) => (
                      <div className="table-row-noti">
                        <div className="bar"></div>
                        <div>
                          {item.image !== "" ? (
                            <>
                              <img
                                src={`/uploads/${item.image}`}
                                // alt="/admin/images/admin/common/admin-icon.svg"
                                style={{
                                  // borderRadius: "50%",
                                  // backgroundColor: "#86b4fc",
                                  width: "35px",
                                  height: "35px",
                                  // marginRight: "10px",
                                }}
                              />
                            </>
                          ) : (
                            <div className="desc" style={{ fontSize: "14px" }}>
                              No image found
                            </div>
                          )}
                        </div>
                        <div className="datetime-arc-mobile">
                          <div className="message">
                            <div
                              className="title"
                              style={{
                                color: "#035189",
                                fontWeight: "500",
                              }}
                            >
                              {item.title}
                            </div>
                            <div className="desc">{item.description}</div>
                          </div>
                        </div>
                        <div className="datetime-arc-mobile">
                          {item.created_at}
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className="features-page-solution"
          style={{ height: "100%", padding: "5rem" }}
        >
          <div className="heading-dotted-machine">
            Notification <span></span>
          </div>
          <div className="features-section-solution">
            <Row>
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <div className="billing-table-cont">
                  <div
                    className="billing-search"
                    style={{ width: "70%", height: "4rem" }}
                  >
                    <div className="search-form">
                      <div className="input-container-notif">
                        {/* <FaCalendar style={{ color: "white" }} /> */}
                        <input
                          //   value={email}
                          type="date"
                          name="from"
                          className="input-signup"
                          placeholder="From"
                          //   onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="input-container-notif">
                        {/* <FaCalendar style={{ color: "white" }} /> */}
                        <input
                          //   value={email}
                          type="date"
                          name="to"
                          className="input-signup"
                          placeholder="To"
                          //   style={{color:'white'}}
                          //   onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <input type="hidden" id="getval" value="All" />
                      <div className="button-fields">
                        <div
                          className="button-cont"
                          style={{
                            marginTop: "-11px",
                          }}
                        >
                          <input
                            type="submit"
                            name="submit"
                            value="Submit"
                            className="input-submit new-btn"
                            // onClick={submitData}
                          />
                        </div>
                      </div>
                    </div>

                    <img
                      src="/images/admin/09-Billing-Table/blue-bg.svg"
                      className="bg-imagee desktop"
                      alt="Background"
                    />
                    <img
                      src="/images/admin/09-Billing-Table/blue-bg.svg"
                      className="bg-imagee mobile"
                      alt="Background"
                    />
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "0rem",
                        width: "200px",
                        height: "60px",
                        backgroundColor: "transparent",
                        left: "120%",
                      }}
                    >
                      <div
                        onClick={() => setIsLatest(false)}
                        style={{
                          borderRadius: "12px",
                          borderStartStartRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          position: "absolute",
                          zIndex: "1",
                          top: "0rem",
                          width: "100px",
                          height: "60px",
                          left: "100px",
                          backgroundColor: isLatest ? "#f1ddc8" : "#e97730",
                          // left: "75rem",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: "700",
                            fontSize: "17px",
                            marginTop: "15px",
                            color: isLatest ? "#e97730" : "white",
                          }}
                        >
                          Archive
                        </p>
                      </div>
                      <div
                        onClick={() => setIsLatest(true)}
                        style={{
                          borderRadius: "12px",
                          borderStartEndRadius: "0px",
                          borderBottomRightRadius: "0px",
                          position: "absolute",
                          zIndex: "1",
                          top: "0rem",
                          width: "100px",
                          height: "60px",
                          backgroundColor: isLatest ? "#e97730" : "#f1ddc8",
                          // left: "75rem",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: "700",
                            fontSize: "17px",
                            marginTop: "15px",
                            color: isLatest ? "white" : "#e97730",
                          }}
                        >
                          Latest
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-buttons-cont"></div>
                  <div
                    className="buttons-container"
                    style={{ padding: "15px" }}
                  >
                    {/* {innerButtons.map((title, idx) => ( */}
                    <Button
                      className="list-btns-web"
                      style={{
                        background: `${
                          activeButton === 1 ? "#035189" : "#f47c20"
                        }`,
                      }}
                      onClick={() => {
                        setActiveButton(1);
                        setAllNotification(adminNoti);
                      }}
                    >
                      Admin Notification (
                      {adminNoti !== null ? adminNoti.length : 0})
                    </Button>

                    <Button
                      className="list-btns-web"
                      style={{
                        background: `${
                          activeButton === 2 ? "#035189" : "#f47c20"
                        }`,
                      }}
                      onClick={() => {
                        setActiveButton(2);
                        setAllNotification(vmNoti);
                      }}
                    >
                      VM Notification ({vmNoti !== null ? vmNoti.length : 0})
                      {/* VM Notification {"("} {vmNoti !== null ? "": 0{")"}} */}
                    </Button>

                    <Button
                      className="list-btns-web"
                      style={{
                        background: `${
                          activeButton === 3 ? "#035189" : "#f47c20"
                        }`,
                      }}
                      onClick={() => {
                        setActiveButton(3);
                        setAllNotification(billNoti);
                      }}
                    >
                      Billing Notification (
                      {billNoti !== null ? billNoti.length : 0})
                    </Button>

                    <Button
                      className="list-btns-web"
                      style={{
                        background: `${
                          activeButton === 4 ? "#035189" : "#f47c20"
                        }`,
                      }}
                      onClick={() => {
                        setActiveButton(4);
                        setAllNotification(paymentNoti);
                      }}
                    >
                      Payment Notification (
                      {paymentNoti !== null ? paymentNoti.length : 0})
                    </Button>

                    {/* <Button
                      className="list-btns-web"
                      style={{
                        background: `${
                          activeButton === 5 ? "#035189" : "#f47c20"
                        }`,
                      }}
                      onClick={() => setActiveButton(5)}
                    >
                      System Profile Noti. {"("}0{")"}
                    </Button> */}

                    <Button
                      className="list-btns-web"
                      style={{
                        background: `${
                          activeButton === 6 ? "#035189" : "#f47c20"
                        }`,
                      }}
                      onClick={() => {
                        setActiveButton(6);
                        setAllNotification(
                          smuser.platform_status == 0
                            ? allNotificationNative.reverse()
                            : allNotificationCloud.reverse()
                        );
                      }}
                    >
                      All Notification (
                      {smuser.platform_status == 0
                        ? allNotificationNative.length
                        : 0}
                      )
                    </Button>
                    {/* ))} */}
                  </div>

                  {isLatest ? (
                    <>
                      <div className="notification-cont">
                        {allNotification &&
                          allNotification.map((item, idx) => (
                            <div className="table-row-noti">
                              <div className="bar"></div>
                              <div className="message">
                                {/* <div className="title">{item.title}</div> */}
                                <div className="title">{item.title}</div>
                                {/* UserIP */}
                                {activeButton === 1 &&
                                  (item.remarks !== null ? (
                                    <div class="desc">
                                      Description : {item.remarks}
                                    </div>
                                  ) : null)}{" "}
                                {activeButton !== 1 && item.UserIP !== null ? (
                                  <div class="desc">
                                    USER LOGIN IP : {item.UserIP}
                                  </div>
                                ) : null}
                                {item.machineID !== null ? (
                                  <div className="desc">
                                    Machine ID : {item.machineID}
                                  </div>
                                ) : null}
                                {item.order_id !== null ? (
                                  <div
                                    className="desc"
                                    onClick={() => handleViewInvoice(item)}
                                  >
                                    {/* onClick={() =>
                                  
                                } paymnet_id*/}
                                    Payment ID :{" "}
                                    <button
                                      style={{
                                        color: "rgb(3, 81, 137)",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {item.order_id}
                                    </button>
                                  </div>
                                ) : null}
                                {item.price !== null ? (
                                  <div className="desc">
                                    Amount:
                                    {appCurrency &&
                                      smuser &&
                                      currencyReturn({
                                        price: item.price,
                                        symbol: smuser.prefer_currency,
                                        rates: appCurrency,
                                      })}
                                  </div>
                                ) : null}
                                {/* </div> */}
                              </div>
                              <div className="datetime">
                                {item.created_at}
                                {/* 16-02-2024 00:08:22 */}
                              </div>
                              <div
                                className="archive-noti"
                                onClick={() => ArchiveCall(item.id)}
                              >
                                <a>
                                  <img
                                    src="/images/admin/17-Notification/archive.png"
                                    title="Move to Archive"
                                  />
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="notification-cont">
                        {/* {allnotiArchive &&
                          allnotiArchive.map((item, idx) => (
                            <div className="table-row-noti">
                              <div className="bar"></div>
                              <div className="message">
                                <div className="title">{item.title}</div>
                                <div className="desc">
                                  Mchine ID : {item.id}
                                </div>
                                <div className="desc">
                                  Payment ID:
                                  <a
                                    href=""
                                    style={{ color: "green" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="invoice"
                                  >
                                    Invoice Order ID - SCVM75210720240216
                                  </a>
                                  <div className="desc">
                                    Amount:
                                    <FontAwesomeIcon
                                      icon={<FaRupeeSign />}
                                      style={{ fontSize: "24px" }}
                                      aria-hidden="true"
                                    />{" "}
                                    50
                                  </div>
                                </div>
                              </div>
                              <div className="datetime">
                                16-02-2024 00:08:22
                              </div>
                            </div>
                          ))} */}
                        {allnotiArchive &&
                          allnotiArchive.map((item, idx) => (
                            // <div className="table-row-noti">
                            //   <div className="bar"></div>
                            //   <div>
                            //     {item.image !== "" ? (
                            //       <>
                            //         <img
                            //           src={`/uploads/${item.image}`}
                            //           // alt="/admin/images/admin/common/admin-icon.svg"
                            //           style={{
                            //             // borderRadius: "50%",
                            //             // backgroundColor: "#86b4fc",
                            //             width: "35px",
                            //             height: "35px",
                            //             // marginRight: "10px",
                            //           }}
                            //         />
                            //       </>
                            //     ) : (
                            //       <div
                            //         className="desc"
                            //         style={{ fontSize: "14px" }}
                            //       >
                            //         No image found
                            //       </div>
                            //     )}
                            //   </div>
                            //   <div className="datetime-arc-mobile">
                            //     <div className="message">
                            //       <div
                            //         className="title"
                            //         style={{
                            //           color: "#035189",
                            //           fontWeight: "500",
                            //         }}
                            //       >
                            //         {item.title}
                            //       </div>
                            //       <div className="desc">{item.description}</div>
                            //     </div>
                            //   </div>
                            //   <div className="datetime-arc-mobile">
                            //     {item.created_at}
                            //   </div>
                            // </div>
                            <div className="table-row-noti">
                              <div className="bar"></div>
                              <div className="message">
                                {/* <div className="title">{item.title}</div> */}
                                <div className="title">{item.title}</div>
                                {/* UserIP */}
                                {item.UserIP !== null ? (
                                  <div class="desc">
                                    USER LOGIN IP : {item.UserIP}
                                  </div>
                                ) : null}

                                {item.machineID !== null ? (
                                  <div className="desc">
                                    Machine ID : {item.machineID}
                                  </div>
                                ) : null}

                                {item.order_id !== null ? (
                                  <div
                                    className="desc"
                                    onClick={() => handleViewInvoice(item)}
                                  >
                                    {/* onClick={() =>
                                  
                                } paymnet_id*/}
                                    Payment ID :{" "}
                                    <button
                                      style={{
                                        color: "rgb(3, 81, 137)",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {item.order_id}
                                    </button>
                                  </div>
                                ) : null}

                                {item.price !== null ? (
                                  <div className="desc">
                                    Amount:
                                    {appCurrency &&
                                      smuser &&
                                      currencyReturn({
                                        price: item.price,
                                        symbol: smuser.prefer_currency,
                                        rates: appCurrency,
                                      })}
                                  </div>
                                ) : null}
                                {/* </div> */}
                              </div>
                              <div className="datetime">
                                {item.created_at}
                                {/* 16-02-2024 00:08:22 */}
                              </div>
                              {/* <div
                                className="archive-noti"
                                onClick={() => ArchiveCall(item.id)}
                              >
                                <a>
                                  <img
                                    src="/images/admin/17-Notification/archive.png"
                                    title="Move to Archive"
                                  />
                                </a>
                              </div> */}
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-1"></div>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
